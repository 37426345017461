<template>
    <div class="segments-tab">
        <vx-card>
            <div class="card-title justify-end">
              <vs-button
                @click="$emit('clickToAddSegment')"
                color="primary"
                icon-pack="feather"
                icon="icon-plus"
                type="filled"
                >{{ LabelConstant.buttonLabelAddSegment }}</vs-button
              >
            </div>
            <vs-table class="table-list" ref="table" :data="segmentList" search>
              <template slot="thead">
                <vs-th>{{ LabelConstant.tableHeaderLabelOptions }}</vs-th>
                <vs-th sort-key="Name">{{ LabelConstant.tableHeaderLabelSegmentName }}</vs-th>
                <vs-th sort-key="Match_Type">{{ LabelConstant.tableHeaderLabelMatchType }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td width="180">
                      <vs-button
                        title="Edit"
                        @click="$emit('clickToGetSegment', tr.Id)"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-edit"
                        class="mr-2 float-left"
                        color="primary"
                      ></vs-button>
                      <vs-button
                        title="View"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-eye"
                        class="mr-2 float-left"
                        color="success"
                        @click="$emit('clickToViewFilter', tr, 'segment')"
                      ></vs-button>
                      <vs-button
                        title="Delete"
                        @click="$emit('clickToDeleteSegment'), deletableSegmentObj = tr"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-trash-2"
                        class="mr-0"
                      ></vs-button>
                    </vs-td>
                    <vs-td :data="tr.Name">
                      <p>{{ tr.Name }}</p>
                    </vs-td>
                    <vs-td :data="tr.Match_Type">
                      <p>{{ tr.Match_Type }}</p>
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
        </vx-card>
    </div>
</template>

<script>

export default ({
    props:{
        segmentList:{
            type: Array,
            required: true
        },
        deletableSegmentObjValue:{
            required:true
        }
    },
    computed:{
        deletableSegmentObj: {
            get() { return this.deletableSegmentObjValue },
            set(deletableSegmentObjValue) { this.$emit('deletableSegmentObjInput', deletableSegmentObjValue) }
        },
    }
})
</script>
