import ContactDetailsLink from "@/components/email-marketing/ContactDetailsLink";
import moment from "moment";

var joinAddresses = function (params) {
    return params.data.Address1 + ", " + params.data.Address2;
  };

export default {
    components: {
        ContactDetailsLink,
      },
    data() {
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
    
        return {
          //active tab
          mainActivetab: 1,
    
          //overview
          topClientDesktop: [],
          topClientMobile: [],
          totalClientDesktopPercentage: null,
          totalClientMobilePercentage: null,
          maxRecordPerPage: 10,
          totalContacts: {
            StatEndValue: 0,
            StatChangePercent: 0,
            name: null,
          },
          SubscribedContacts: {
            StatEndValue: 0,
            StatChangePercent: 0,
            name: null,
          },
          unSubscribedContacts: {
            StatEndValue: 0,
            StatChangePercent: 0,
            name: null,
          },
          segmentCreated: {
            StatEndValue: 0,
            StatChangePercent: 0,
            name: null,
          },
          campaignPerformance: {
            series: [
              {
                name: "Open Rate",
                data: [],
              },
              {
                name: "Click Rate",
                data: [],
              },
            ],
            chartOptions: {
              chart: {
                height: 350,
                zoom: {
                  enabled: false,
                },
              },
              colors: [
                token[uid].themeDetail.tempChartColor1,
                token[uid].themeDetail.tempChartColor2,
              ],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "straight",
              },
              grid: {
                row: {
                  colors: ["#f3f3f3", "transparent"],
                  opacity: 0.5,
                },
              },
              xaxis: {
                categories: [],
              },
            },
          },
          radialDasktopChart: {
            series: [],
            chartOptions: {
              colors: [token[uid].themeDetail.tempChartColor1],
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      fontSize: "22px",
                    },
                    value: {
                      fontSize: "16px",
                      offsetY: 6,
                    },
                    total: {
                      show: true,
                      label: "Desktop",
                    },
                  },
                },
              },
            },
          },
          radialMobileChart: {
            series: [],
            chartOptions: {
              colors: ["#7AC546"],
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      fontSize: "22px",
                    },
                    value: {
                      fontSize: "16px",
                      offsetY: 6,
                    },
                    total: {
                      show: true,
                      label: "Mobile",
                    },
                  },
                },
              },
            },
          },
          /* Map */
          chartData: [["Country", "Unique Visitors"]],
          chartOptions: {
            colorAxis: {
              colors: [token[uid].themeDetail.tempChartColor1],
            },
          },
          countryLocation: [],
    
          // Contacts
          context: null,
          frameworkComponents: null,
          gridApi: null,
          maxPageNumbers: 7,
          searchQuery: "",
          gridOptions: {},
          defaultColDef: {
            sortable: true,
            editable: false,
            resizable: true,
            suppressMenu: true,
          },
          columnDefs: [],
    
          contactsTableFieldDefinition: [],
          contactsData: [],
          filterSelectedTagObjs: [],
          filterSelectedSegmentObj: null,
          contactDetails: null,
          isOpenNote: false,
          addContactNote: null,
          addContactTagOptions: [],
          addContactTag: null,
          openRateChart: {
            series: [0],
            chartOptions: {
              colors: ["#7AC546"],
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      fontSize: "22px",
                      show: false,
                    },
                    value: {
                      fontSize: "16px",
                      offsetY: 6,
                    },
                    total: {
                      show: false,
                    },
                  },
                },
              },
            },
          },
          clickRateChart: {
            series: [0],
            chartOptions: {
              colors: ["#7AC546"],
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      fontSize: "22px",
                      show: false,
                    },
                    value: {
                      fontSize: "16px",
                      offsetY: 6,
                    },
                    total: {
                      show: false,
                    },
                  },
                },
              },
            },
          },
          customerJourney: null,
          customerJourneyStore: null,
          tempCustomerJourney: [
            {
              Remote_Id: "1",
              Touchpoint_Type: "Website Visits",
              Icon_Name: "",
              Icon_Url: "",
              Message1: "Website Visit",
              Message2: "looked at 3 pages",
              Date_Short: "2020-12-22T01:42:48.077244",
              Date_Long: "2020-12-22T01:42:48.077244",
              Time: "11:01 AM",
            },
            {
              Remote_Id: "2",
              Touchpoint_Type: "Phone Calls",
              Icon_Name: "",
              Icon_Url: "",
              Message1: "Phone Call",
              Message2: "to 888-123-456",
              Date_Short: "2020-12-22T01:42:48.077244",
              Date_Long: "2020-12-22T01:42:48.077244",
              Time: "9:58 AM",
            },
            {
              Remote_Id: "3",
              Touchpoint_Type: "Clicks",
              Icon_Name: "",
              Icon_Url: "",
              Message1: "Clicked Email",
              Message2: "Big news! Lots of new stuff",
              Date_Short: "2020-12-22T01:42:48.077244",
              Date_Long: "2020-12-22T01:42:48.077244",
              Time: "9:21 AM",
            },
            {
              Remote_Id: "4",
              Touchpoint_Type: "Opens",
              Icon_Name: "",
              Icon_Url: "",
              Message1: "Opened Email",
              Message2: "Big news! Lots of new stuff",
              Date_Short: "2020-12-22T01:42:48.077244",
              Date_Long: "2020-12-22T01:42:48.077244",
              Time: "9:20 AM",
            },
            {
              Remote_Id: "5",
              Touchpoint_Type: "Sends",
              Icon_Name: "",
              Icon_Url: "",
              Message1: "Sent Email",
              Message2: "Big news! Lots of new stuff",
              Date_Short: "2020-12-23T01:42:48.077244",
              Date_Long: "2020-12-23T01:42:48.077244",
              Time: "11:20 AM",
            },
            {
              Remote_Id: "6",
              Touchpoint_Type: "Notes",
              Icon_Name: "",
              Icon_Url: "",
              Message1: "Contacted Added",
              Message2: "from import",
              Date_Short: "2020-12-23T01:42:48.077244",
              Date_Long: "2020-12-23T01:42:48.077244",
              Time: "11:20 AM",
            },
          ],
          selectedCustomerJourneyTouchPointType: null,
          customerJourneyTouchPointType: [
            {
              label: "Sends",
              name: "Sends",
            },
            {
              label: "Opens",
              name: "Opens",
            },
            {
              label: "Clicks",
              name: "Clicks",
            },
            {
              label: "Website Visits",
              name: "Website Visits",
            },
            {
              label: "Phone Calls",
              name: "Phone Calls",
            },
            {
              label: "Text Message",
              name: "Text Message",
            },
            {
              label: "Facebook Post",
              name: "Facebook Post",
            },
            {
              label: "Facebook Message",
              name: "Facebook Message",
            },
            {
              label: "Twitter Mention",
              name: "Twitter Mention",
            },
            {
              label: "GMB Post",
              name: "GMB Post",
            },
            {
              label: "GMB Review",
              name: "GMB Review",
            },
            {
              label: "Facebook Review",
              name: "Facebook Review",
            },
            {
              label: "Unsubscribed",
              name: "Unsubscribed",
            },
            {
              label: "Subscribed",
              name: "Subscribed",
            },
            {
              label: "Notes",
              name: "Notes",
            },
          ],
          deleteContactPopupActive: false,
    
          dynamicCustomField: [],
          addContactPopupActive: false,
          addContactEmailAddress: null,
          addContactCompany: null,
          addContactFirstName: null,
          addContactLastName: null,
          addContactAddress1: null,
          addContactAddress2: null,
          addContactCity: null,
          addContactState: null,
          addContactZip: null,
          addContactCountry: null,
          addContactPhoneNumber: null,
          addContactFacebookProfile: null,
          addContactLinkedinProfile: null,
          addContactTwitterProfile: null,
          addContactTagObj: [],
          addContactCustomFields: [],
    
          //Store boolean value for Import Contacts from CSV File popup open or close
          importContactActive: false,
          importContactFileError: null,
          //Store selected field from csv for mapping
          importEmailMapField: null,
          importCompanyMapField: null,
          importFirstNameMapField: null,
          importLastNameMapField: null,
          importAddress1MapField: null,
          importAddress2MapField: null,
          importCityMapField: null,
          importStateMapField: null,
          importZipMapField: null,
          importCountryMapField: null,
          importPhoneNumberMapField: null,
          importFacebookProfileMapField: null,
          importLinkedinProfileMapField: null,
          importTwitterProfileMapField: null,
          importCustomFieldMapField: null,
    
          importContactsTags: [],
    
          //Store list of csv fields for mapping dropdown
          importCSVFieldMapOptions: [],
          //Store name of csv file that would be imported
          importContactFile: "",
          csvFileName: null,
          csv: null,
          sortOrders: {},
          sortKey: "",
          //Store boolean value for Import Contact button enable or disable
          isImportContactsButton: false,
    
          editContactPopupActive: false,
          contactDetailsEdit: null,
    
          deleteSelectedContact: false,
          unsubscribeSelectedContact: false,
          resubscribeSelectedContact: false,
    
          // Sengments
          segmentList: [],
    
          addSegmentActive: false,
          segmentAddEditPopupTitle: null,
          segmentName: null,
          matchType: "all",
          matchTypeOptions: [
            {
              label: "All",
              name: "all",
            },
            {
              label: "Any",
              name: "any",
            },
          ],
          segmentFirstListOptions: [],
          segmentFirstListSelectedOption: [],
          segmentSecondListOptions: [],
          segmentSecondListSelectedOption: [],
          segmentThirdListOptions: [],
          segmentThirdListSelectedOption: [],
          segmentFourthFieldValue: [],
          segmentFourthListOptions: [],
    
          deleteSegmentActive: false,
          deletableSegmentObj: {},
    
          segmentEditMode: false,
          segmentDetails: null,
    
          // Tags
          tagId: 0,
          addTagActive: false,
          deleteTagActive: false,
          tags: [],
          activeContact: false,
          addTagName: null,
        };
      },
      mounted() {
        // this.gridApi = this.gridOptions.api
        // if(this.$vs.rtl) {
        //     const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
        //     header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
        // }
      },
      beforeMount() {
        this.context = { componentParent: this };
        this.frameworkComponents = {
          contactDetailsLink: ContactDetailsLink,
        };
      },
      created() {
        this.getTopLocations();
        this.getTotalContacts();
        this.getSubcribedContacts();
        this.getUnsubcribedContacts();
        this.getSegmentCreated();
        this.getEmailClients();
        this.getLatestCampaignPerformance();
    
        //tags
        this.getTagsList();
    
        //Segment
        this.listSegments();
        this.getCriteriaAvailable();
    
        //Contacts
        this.getContactFieldDefinition();
      },
      methods: {
        changeTab() {
          this.mainActivetab = 1;
        },
        // get total contacts stats api
        getTotalContacts() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactOverview/GetTotalContacts")
            .then((response) => {
              let data = response.data;
              this.totalContacts.StatChangePercent = data.StatPercentValue;
              this.totalContacts.StatEndValue = data.StatValue;
              this.totalContacts.name = data.StatValueName;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        // get subscribed contacts stats api
        getSubcribedContacts() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactOverview/GetTotalSubscribedContacts")
            .then((response) => {
              let data = response.data;
              this.SubscribedContacts.StatChangePercent = data.StatPercentValue;
              this.SubscribedContacts.StatEndValue = data.StatValue;
              this.SubscribedContacts.name = data.StatValueName;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        // get unsubcribed contacts stats api
        getUnsubcribedContacts() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactOverview/GetTotalUnsubscribedContacts")
            .then((response) => {
              let data = response.data;
              this.unSubscribedContacts.StatChangePercent = data.StatPercentValue;
              this.unSubscribedContacts.StatEndValue = data.StatValue;
              this.unSubscribedContacts.name = data.StatValueName;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        // get segment created stats api
        getSegmentCreated() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactOverview/GetTotalSegments")
            .then((response) => {
              let data = response.data;
              this.segmentCreated.StatChangePercent = data.StatPercentValue;
              this.segmentCreated.StatEndValue = data.StatValue;
              this.segmentCreated.name = data.StatValueName;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        getTopLocations() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactOverview/GetLocationStats")
            .then((response) => {
              let data = response.data;
              this.countryLocation = data;
              let toploc = [["Country", "Unique Visitors"]];
    
              data.forEach((el) => {
                toploc.push([el.x, el.SecondGroup]);
              });
    
              this.chartData = toploc;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        // get emails clients charts
        getEmailClients() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactOverview/GetEmailClientStats")
            .then((response) => {
              let data = response.data;
              if (data[0].EmailClientType == "Desktop") {
                let series = [];
                series.push(data[0].EmailClientPercentage);
                this.radialDasktopChart.series = series;
                this.topClientDesktop = data[0].TopClients;
                this.totalClientDesktopPercentage = data[0].EmailClientPercentage;
              }
              if (data[1].EmailClientType == "Mobile") {
                let series = [];
                series.push(data[1].EmailClientPercentage);
                this.radialMobileChart.series = series;
                this.topClientMobile = data[1].TopClients;
                this.totalClientMobilePercentage = data[1].EmailClientPercentage;
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        // lastest campaign performance graph api
        getLatestCampaignPerformance() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactOverview/GetLatestCampaignPerformance")
            .then((response) => {
              let data = response.data;
              let firstGroup = [];
              let secondGroup = [];
              let x = [];
              data.forEach((el) => {
                x.push(el.x);
                firstGroup.push(el.FirstGroup);
                secondGroup.push(el.SecondGroup);
              });
              this.campaignPerformance.chartOptions = {
                ...this.campaignPerformance.chartOptions,
                ...{
                  xaxis: {
                    categories: x,
                  },
                },
              };
              this.campaignPerformance.series = [
                {
                  name: "Open Rate",
                  type: "line",
                  data: firstGroup,
                },
                {
                  name: "Click Rate",
                  type: "line",
                  data: secondGroup,
                },
              ];
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        // tag list api
        getTagsList() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactTag/ListTags")
            .then((response) => {
              let data = response.data;
              this.tags = data;
              if (this.$route.params.tagSelected) {
                for (let i = 0; i < this.tags.length; i++) {
                  if (this.$route.params.tagSelected == this.tags[i].Id) {
                    this.filterSelectedTagObjs.push(this.tags[i]);
                  }
                }
                this.contactsFilterByTag();
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        // edit tag api call
        editTag(id) {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactTag/GetTag?Id=" + id)
            .then((response) => {
              let data = response.data;
              this.addTagName = data.Tag_Name;
              this.tagId = data.Id;
              this.addTagActive = true;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        // add edit tag api
        addTag() {
          this.$validator.validateAll("tagForm").then(async (result) => {
            if (result) {
              let input;
              let url;
              let msg;
              if (this.tagId != 0) {
                msg = "Tag updated successfully.";
                url = "/ws/EmailMarketingContactTag/UpdateTag";
                input = {
                  Id: this.tagId,
                  Tag_name: this.addTagName,
                };
              } else {
                msg = "Tag added successfully.";
                url = "/ws/EmailMarketingContactTag/AddTag";
                input = {
                  Tag_name: this.addTagName,
                };
              }
              this.$vs.loading();
              this.axios
                .post(url, input)
                .then(() => {
                  this.$vs.notify({
                    title: "Success",
                    text: msg,
                    color: "success",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000,
                  });
                  this.addTagActive = false;
                  this.getTagsList();
                  this.addTagName = "";
                  this.tagId = 0;
                  this.$vs.loading.close();
                })
                .catch((e) => {
                  this.$vs.loading.close();
                  this.showError(e);
                  this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                  });
                });
            }
          });
        },
        // cancel add edit
        cancelAddTag() {
          this.addTagActive = false;
          this.addTagName = "";
          this.tagId = 0;
          setTimeout(() => {
            this.errors.clear();
          }, 100);
        },
        // tag delete confirmation
        deleteTagConfirmation(record) {
          this.deleteTagActive = true;
          this.addTagName = record.Tag_Name;
          this.tagId = record.Id;
        },
        // call delete tag api
        deleteTag() {
          let input;
          let url;
          let msg;
    
          msg = "Tag deleted successfully.";
          url = "/ws/EmailMarketingContactTag/DeleteTag";
          input = {
            Id: this.tagId,
            Tag_name: this.addTagName,
          };
    
          this.$vs.loading();
          this.axios
            .post(url, input)
            .then(() => {
              this.$vs.notify({
                title: "Success",
                text: msg,
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.deleteTagActive = false;
              this.getTagsList();
              this.addTagName = "";
              this.tagId = 0;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
    
        //Segment
        listSegments() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactSegment/ListSegments")
            .then((response) => {
              let data = response.data;
              this.segmentList = data;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        getCriteriaAvailable() {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactSegment/GetCriteriaAvailable")
            .then((response) => {
              let data = response.data;
              this.segmentFirstListOptions = data;
              this.segmentFirstListSelectedOption[0] = this.segmentFirstListOptions[0];
              this.segmentSecondListOptions[0] = this.segmentFirstListOptions[0].Possible_Evaluations;
              this.segmentSecondListSelectedOption[0] = this.segmentFirstListOptions[0].Possible_Evaluations[0];
              this.segmentThirdListOptions[0] = this.segmentFirstListOptions[0].Possible_Values;
              this.segmentThirdListSelectedOption[0] = this.segmentFirstListOptions[0].Possible_Values[0];
              if (
                this.segmentFirstListOptions[0].Possible_Values[0]
                  .Possible_Value_Type == "list"
              ) {
                this.segmentFourthFieldValue[0] = this.segmentFirstListOptions[0].Possible_Values[0].Possible_Value_List[0];
                this.segmentFourthListOptions[0] = this.segmentFirstListOptions[0].Possible_Values[0].Possible_Value_List;
              } else {
                this.segmentFourthFieldValue[0] = null;
                this.segmentFourthListOptions[0] = this.segmentFirstListOptions[0].Possible_Values[0].Possible_Value_List;
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        changeSecondAndThirdListOptions(index) {
          // console.log('-------------- index -------------', index)
          for (let i = 0; i < this.segmentFirstListOptions.length; i++) {
            if (
              this.segmentFirstListOptions[i].Id ==
              this.segmentFirstListSelectedOption[index].Id
            ) {
              this.segmentSecondListOptions[index] = this.segmentFirstListOptions[
                i
              ].Possible_Evaluations;
              this.segmentSecondListSelectedOption[
                index
              ] = this.segmentFirstListOptions[i].Possible_Evaluations[0];
              this.segmentThirdListOptions[index] = this.segmentFirstListOptions[
                i
              ].Possible_Values;
              this.segmentThirdListSelectedOption[
                index
              ] = this.segmentFirstListOptions[i].Possible_Values[0];
              if (
                this.segmentThirdListSelectedOption[index].Possible_Value_Type ==
                "list"
              ) {
                // console.log('----------- if -------------',this.segmentFirstListOptions[i])
                this.segmentFourthFieldValue[index] = this.segmentFirstListOptions[
                  i
                ].Possible_Values[0].Possible_Value_List[0];
                this.segmentFourthListOptions[index] = this.segmentFirstListOptions[
                  i
                ].Possible_Values[0].Possible_Value_List;
              } else {
                // console.log('----------- else -------------',this.segmentFirstListOptions[i])
                this.segmentFourthFieldValue[index] = null;
                this.segmentFourthListOptions[index] = this.segmentFirstListOptions[
                  i
                ].Possible_Values[0].Possible_Value_List;
              }
            }
          }
        },
        changeFourthListOptions(index) {
          for (let i = 0; i < this.segmentFirstListOptions.length; i++) {
            if (
              this.segmentFirstListOptions[i].Id ==
              this.segmentFirstListSelectedOption[index].Id
            ) {
              if (
                this.segmentThirdListSelectedOption[index].Possible_Value_Type ==
                "list"
              ) {
                this.segmentFourthFieldValue[index] = this.segmentFirstListOptions[
                  i
                ].Possible_Values[index].Possible_Value_List[0];
                this.segmentFourthListOptions[index] = this.segmentFirstListOptions[
                  i
                ].Possible_Values[index].Possible_Value_List;
              } else {
                this.segmentFourthFieldValue[index] = null;
                this.segmentFourthListOptions[index] = this.segmentFirstListOptions[
                  i
                ].Possible_Values[index].Possible_Value_List;
              }
            }
          }
        },
        addCriteria() {
          this.segmentFirstListSelectedOption.push(this.segmentFirstListOptions[0]);
          this.segmentSecondListOptions.push(
            this.segmentFirstListOptions[0].Possible_Evaluations
          );
          this.segmentSecondListSelectedOption.push(
            this.segmentFirstListOptions[0].Possible_Evaluations[0]
          );
          this.segmentThirdListOptions.push(
            this.segmentFirstListOptions[0].Possible_Values
          );
          this.segmentThirdListSelectedOption.push(
            this.segmentFirstListOptions[0].Possible_Values[0]
          );
          if (
            this.segmentFirstListOptions[0].Possible_Values[0]
              .Possible_Value_Type == "list"
          ) {
            this.segmentFourthFieldValue.push(
              this.segmentFirstListOptions[0].Possible_Values[0]
                .Possible_Value_List[0]
            );
            this.segmentFourthListOptions.push(
              this.segmentFirstListOptions[0].Possible_Values[0].Possible_Value_List
            );
          } else {
            this.segmentFourthFieldValue.push(null);
            this.segmentFourthListOptions.push(
              this.segmentFirstListOptions[0].Possible_Values[0].Possible_Value_List
            );
          }
        },
        removeCriteria(index) {
          this.segmentFirstListSelectedOption.splice(index, 1);
          this.segmentSecondListOptions.splice(index, 1);
          this.segmentSecondListSelectedOption.splice(index, 1);
          this.segmentThirdListOptions.splice(index, 1);
          this.segmentThirdListSelectedOption.splice(index, 1);
          this.segmentFourthFieldValue.splice(index, 1);
          this.segmentFourthListOptions.splice(index, 1);
        },
        cancelAddSegment() {
          this.segmentEditMode = false;
          this.segmentAddEditPopupTitle = null;
          this.addSegmentActive = false;
          this.segmentDetails = null;
    
          this.segmentName = null;
          this.matchType = "all";
          this.segmentFirstListSelectedOption = [];
          this.segmentSecondListOptions = [];
          this.segmentSecondListSelectedOption = [];
          this.segmentThirdListOptions = [];
          this.segmentThirdListSelectedOption = [];
          this.segmentFourthFieldValue = [];
          this.segmentFourthListOptions = [];
    
          this.segmentFirstListSelectedOption[0] = this.segmentFirstListOptions[0];
          this.segmentSecondListOptions[0] = this.segmentFirstListOptions[0].Possible_Evaluations;
          this.segmentSecondListSelectedOption[0] = this.segmentFirstListOptions[0].Possible_Evaluations[0];
          this.segmentThirdListOptions[0] = this.segmentFirstListOptions[0].Possible_Values;
          this.segmentThirdListSelectedOption[0] = this.segmentFirstListOptions[0].Possible_Values[0];
          if (
            this.segmentFirstListOptions[0].Possible_Values[0]
              .Possible_Value_Type == "list"
          ) {
            this.segmentFourthFieldValue[0] = this.segmentFirstListOptions[0].Possible_Values[0].Possible_Value_List[0];
            this.segmentFourthListOptions[0] = this.segmentFirstListOptions[0].Possible_Values[0].Possible_Value_List;
          } else {
            this.segmentFourthFieldValue[0] = null;
            this.segmentFourthListOptions[0] = this.segmentFirstListOptions[0].Possible_Values[0].Possible_Value_List;
          }
        },
        addSegment() {
          this.$vs.loading();
          let conditions = [];
          for (let i = 0; i < this.segmentSecondListOptions.length; i++) {
            let criteriaOrder = i + 1;
            let criteriaId = this.segmentFirstListSelectedOption[i].Id;
            let criteriaEvaluationId = this.segmentSecondListSelectedOption[i].Id;
            let criteriaValueId = this.segmentThirdListSelectedOption[i].Id;
            let criteriaValue;
            if (typeof this.segmentFourthFieldValue[i] == "object") {
              criteriaValue = this.segmentFourthFieldValue[i];
            } else {
              criteriaValue = {
                Key: this.segmentFourthFieldValue[i],
                Value: this.segmentFourthFieldValue[i],
              };
            }
    
            conditions.push({
              Criteria_Order: criteriaOrder,
              Criteria_Id: criteriaId,
              Criteria_Evaluation_Id: criteriaEvaluationId,
              Criteria_Value_Id: criteriaValueId,
              Criteria_Value: criteriaValue,
            });
          }
    
          let addSegmentParams = {
            Id: 0,
            Name: this.segmentName,
            Match_Type: this.matchType,
            Conditions: conditions,
            Current_Count: 0,
          };
    
          this.axios
            .post("/ws/EmailMarketingContactSegment/AddSegment", addSegmentParams)
            .then(() => {
              this.cancelAddSegment();
              this.listSegments();
              this.$vs.notify({
                title: "Success",
                text: "Segment created successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
        cancelDeleteSegment() {
          this.deleteSegmentActive = false;
          this.deletableSegmentObj = {};
        },
        deleteSegment() {
          this.$vs.loading();
          this.axios
            .post(
              "/ws/EmailMarketingContactSegment/DeleteSegment",
              this.deletableSegmentObj
            )
            .then(() => {
              this.cancelDeleteSegment();
              this.listSegments();
              this.$vs.notify({
                title: "Success",
                text: "Segment deleted successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
        getSegment(id) {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContactSegment/GetSegment?id=" + id)
            .then((response) => {
              this.segmentEditMode = true;
              this.segmentAddEditPopupTitle = "Edit Segment";
              let data = response.data;
              this.segmentDetails = data;
              this.segmentName = data.Name;
              this.matchType = data.Match_Type;
              for (let i = 0; i < data.Conditions.length; i++) {
                for (let j = 0; j < this.segmentFirstListOptions.length; j++) {
                  if (
                    data.Conditions[i].Criteria_Id ==
                    this.segmentFirstListOptions[j].Id
                  ) {
                    this.segmentFirstListSelectedOption[
                      i
                    ] = this.segmentFirstListOptions[j];
                    this.segmentSecondListOptions[i] = this.segmentFirstListOptions[
                      j
                    ].Possible_Evaluations;
                    this.segmentThirdListOptions[i] = this.segmentFirstListOptions[
                      j
                    ].Possible_Values;
                    for (
                      let k = 0;
                      k <
                      this.segmentFirstListOptions[j].Possible_Evaluations.length;
                      k++
                    ) {
                      if (
                        data.Conditions[i].Criteria_Evaluation_Id ==
                        this.segmentFirstListOptions[j].Possible_Evaluations[k].Id
                      ) {
                        this.segmentSecondListSelectedOption[
                          i
                        ] = this.segmentFirstListOptions[j].Possible_Evaluations[k];
                      }
                    }
                    for (
                      let x = 0;
                      x < this.segmentFirstListOptions[j].Possible_Values.length;
                      x++
                    ) {
                      if (
                        data.Conditions[i].Criteria_Value_Id ==
                        this.segmentFirstListOptions[j].Possible_Values[x].Id
                      ) {
                        this.segmentThirdListSelectedOption[
                          i
                        ] = this.segmentFirstListOptions[j].Possible_Values[x];
                        this.segmentFourthListOptions[
                          i
                        ] = this.segmentFirstListOptions[j].Possible_Values[
                          x
                        ].Possible_Value_List;
                        if (
                          this.segmentFirstListOptions[j].Possible_Values[x]
                            .Possible_Value_Type == "list"
                        ) {
                          this.segmentFourthFieldValue[i] =
                            data.Conditions[i].Criteria_Value;
                        } else {
                          this.segmentFourthFieldValue[i] =
                            data.Conditions[i].Criteria_Value.Value;
                        }
                      }
                    }
                  }
                }
              }
              this.addSegmentActive = true;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        updateSegment() {
          this.$vs.loading();
          let conditions = [];
          for (let i = 0; i < this.segmentSecondListOptions.length; i++) {
            let criteriaOrder = i + 1;
            let criteriaId = this.segmentFirstListSelectedOption[i].Id;
            let criteriaEvaluationId = this.segmentSecondListSelectedOption[i].Id;
            let criteriaValueId = this.segmentThirdListSelectedOption[i].Id;
            let criteriaValue;
            if (typeof this.segmentFourthFieldValue[i] == "object") {
              criteriaValue = this.segmentFourthFieldValue[i];
            } else {
              criteriaValue = {
                Key: this.segmentFourthFieldValue[i],
                Value: this.segmentFourthFieldValue[i],
              };
            }
    
            conditions.push({
              Criteria_Order: criteriaOrder,
              Criteria_Id: criteriaId,
              Criteria_Evaluation_Id: criteriaEvaluationId,
              Criteria_Value_Id: criteriaValueId,
              Criteria_Value: criteriaValue,
            });
          }
    
          let updateSegmentParams = {
            Id: this.segmentDetails.Id,
            Name: this.segmentName,
            Match_Type: this.matchType,
            Conditions: conditions,
            Current_Count: 0,
          };
    
          this.axios
            .post(
              "/ws/EmailMarketingContactSegment/UpdateSegment",
              updateSegmentParams
            )
            .then(() => {
              this.cancelAddSegment();
              this.listSegments();
              this.$vs.notify({
                title: "Success",
                text: "Segment updated successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
    
        //Contacts
        onGridReady() {
          this.gridApi = this.gridOptions.api;
          if (this.$vs.rtl) {
            const header = this.$refs.agGridTable.$el.querySelector(
              ".ag-header-container"
            );
            header.style.left =
              "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
          }
        },
        updateSearchQuery(val) {
          this.gridApi.setQuickFilter(val);
        },
        async getContactFieldDefinition() {
          this.$vs.loading();
          await this.axios
            .get("/ws/EmailMarketingContact/GetContactFieldDefinition")
            .then((response) => {
              let data = response.data;
              this.contactsTableFieldDefinition = data;
              this.dynamicCustomField = [];
              for (let i = 0; i < this.contactsTableFieldDefinition.length; i++) {
                if (
                  !this.contactsTableFieldDefinition[i].IsDefault &&
                  this.contactsTableFieldDefinition[i].Field_Data_Type != "image"
                ) {
                  this.dynamicCustomField.push(
                    this.contactsTableFieldDefinition[i]
                  );
                }
                var fieldName = this.contactsTableFieldDefinition[
                  i
                ].Field_Name.replace(/ /g, "_");
                var fieldLabel = this.contactsTableFieldDefinition[i].Field_Label;
                if (this.contactsTableFieldDefinition[i].Display_Column) {
                  if (
                    this.contactsTableFieldDefinition[i].Field_Data_Type != "image"
                  ) {
                    if (fieldName == "Email_Address") {
                      this.columnDefs.push({
                        headerName: fieldLabel,
                        field: fieldName,
                        width: 200,
                        pinned: "left",
                        filter: true,
                        checkboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        headerCheckboxSelection: true,
                        cellRenderer: "contactDetailsLink",
                      });
                    } else if (fieldName == "Address") {
                      this.columnDefs.push({
                        headerName: fieldLabel,
                        valueGetter: joinAddresses,
                        filter: true,
                        width: 250,
                      });
                    } else {
                      this.columnDefs.push({
                        headerName: fieldLabel,
                        field: fieldName,
                        filter: true,
                        width: 140,
                      });
                    }
                  }
                }
              }
              this.$vs.loading.close();
              if (!this.$route.params.tagSelected) {
                let listContactsParams = {
                  Fitler_Type: "",
                  Filter_Segment: "",
                  Filter_Tag: [],
                  Filter_Search_Value: "",
                  Order_By: "Email_Address",
                  Order_By_Type: "asc",
                  Number_Per_Page: 0,
                  Skip_Number_Of_Pages: 0,
                };
                this.listContacts(listContactsParams);
              }
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        contactsFilterByTag() {
          this.filterSelectedSegmentObj = null;
          let tagsParams = [];
          if (this.filterSelectedTagObjs) {
            for (let i = 0; i < this.filterSelectedTagObjs.length; i++) {
              tagsParams.push(this.filterSelectedTagObjs[i].Id.toString());
            }
            let listContactsParams = {
              Fitler_Type: "Tag",
              Filter_Segment: "",
              Filter_Tag: tagsParams,
              Filter_Search_Value: "",
              Order_By: "Email_Address",
              Order_By_Type: "asc",
              Number_Per_Page: 0,
              Skip_Number_Of_Pages: 0,
            };
            this.listContacts(listContactsParams);
          } else {
            let listContactsParams = {
              Fitler_Type: "",
              Filter_Segment: "",
              Filter_Tag: [],
              Filter_Search_Value: "",
              Order_By: "Email_Address",
              Order_By_Type: "asc",
              Number_Per_Page: 0,
              Skip_Number_Of_Pages: 0,
            };
            this.listContacts(listContactsParams);
          }
        },
        contactsFilterBySegment() {
          this.filterSelectedTagObjs = [];
          if (this.filterSelectedSegmentObj) {
            let listContactsParams = {
              Fitler_Type: "Segment",
              Filter_Segment: this.filterSelectedSegmentObj.Id.toString(),
              Filter_Tag: [],
              Filter_Search_Value: "",
              Order_By: "Email_Address",
              Order_By_Type: "asc",
              Number_Per_Page: 0,
              Skip_Number_Of_Pages: 0,
            };
            this.listContacts(listContactsParams);
          } else {
            let listContactsParams = {
              Fitler_Type: "",
              Filter_Segment: "",
              Filter_Tag: [],
              Filter_Search_Value: "",
              Order_By: "Email_Address",
              Order_By_Type: "asc",
              Number_Per_Page: 0,
              Skip_Number_Of_Pages: 0,
            };
            this.listContacts(listContactsParams);
          }
        },
        listContacts(listContactsParams) {
          this.$vs.loading();
          this.axios
            .post("/ws/EmailMarketingContact/ListContacts", listContactsParams)
            .then((response) => {
              let data = response.data;
              this.contactsData = data;
              for (let i = 0; i < this.contactsData.length; i++) {
                for (let j = 0; j < this.contactsData[i].CustomFields.length; j++) {
                  if (
                    this.contactsData[i].CustomFields[j].Field_Type.Field_Type ==
                      "dropdown" ||
                    this.contactsData[i].CustomFields[j].Field_Type.Field_Type ==
                      "radio"
                  ) {
                    for (
                      let k = 0;
                      k < this.contactsData[i].CustomFields[j].Options.length;
                      k++
                    ) {
                      if (
                        this.contactsData[i].CustomFields[j].Current_Value ==
                        this.contactsData[i].CustomFields[j].Options[k].Id
                      ) {
                        this.contactsData[i][
                          this.contactsData[i].CustomFields[j].Field_Label.replace(
                            / /g,
                            "_"
                          )
                        ] = this.contactsData[i].CustomFields[j].Options[
                          k
                        ].Possible_Value;
                      }
                    }
                  } else {
                    this.contactsData[i][
                      this.contactsData[i].CustomFields[j].Field_Label.replace(
                        / /g,
                        "_"
                      )
                    ] = this.contactsData[i].CustomFields[j].Current_Value;
                  }
                }
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        openAddContactPopup() {
          this.addContactCustomFields = [];
          for (let i = 0; i < this.dynamicCustomField.length; i++) {
            this.addContactCustomFields.push({
              Id: this.dynamicCustomField[i].Id,
              Field_Label: this.dynamicCustomField[i].Field_Label,
              Field_Type: {
                Id: this.dynamicCustomField[i].Id,
                Field_Type_Label: this.dynamicCustomField[i].Field_Label,
                Field_Type: this.dynamicCustomField[i].Field_Data_Type,
              },
              Required: this.dynamicCustomField[i].Required,
              Visible: this.dynamicCustomField[i].Visible,
              Merge_Tag: this.dynamicCustomField[i].Merge_Tag,
              Default_Value: this.dynamicCustomField[i].Default_Value,
              Options: this.dynamicCustomField[i].Possible_Values,
              Current_Value: null,
            });
          }
          this.addContactPopupActive = true;
        },
        cancelAddContact() {
          this.addContactPopupActive = false;
          this.addContactEmailAddress = null;
          this.addContactCompany = null;
          this.addContactFirstName = null;
          this.addContactLastName = null;
          this.addContactAddress1 = null;
          this.addContactAddress2 = null;
          this.addContactCity = null;
          this.addContactState = null;
          this.addContactZip = null;
          this.addContactCountry = null;
          this.addContactPhoneNumber = null;
          this.addContactFacebookProfile = null;
          this.addContactLinkedinProfile = null;
          this.addContactTwitterProfile = null;
          this.addContactTagObj = [];
          this.addContactCustomeFields = [""];
        },
        addContact() {
          this.$vs.loading();
          let addContactParams = {
            Id: 0,
            Email_Address: this.addContactEmailAddress,
            Company: this.addContactCompany,
            First_Name: this.addContactFirstName,
            Last_Name: this.addContactLastName,
            Address1: this.addContactAddress1,
            Address2: this.addContactAddress2,
            City: this.addContactCity,
            State: this.addContactState,
            Zip: this.addContactZip,
            Country: this.addContactCountry,
            Phone: this.addContactPhoneNumber,
            Facebook_Profile: this.addContactFacebookProfile,
            LinkedIn_Profile: this.addContactLinkedinProfile,
            Twitter_Profile: this.addContactTwitterProfile,
            Tags: this.addContactTagObj,
            CustomFields: this.addContactCustomFields,
          };
          this.axios
            .post("/ws/EmailMarketingContact/AddContact", addContactParams)
            .then(() => {
              this.cancelAddContact();
              let listContactsParams = {
                Fitler_Type: "",
                Filter_Segment: "",
                Filter_Tag: [],
                Filter_Search_Value: "",
                Order_By: "Email_Address",
                Order_By_Type: "asc",
                Number_Per_Page: 0,
                Skip_Number_Of_Pages: 0,
              };
              this.listContacts(listContactsParams);
              this.$vs.notify({
                title: "Success",
                text: "Contact Created successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
        removeFilterTag(index) {
          this.filterSelectedTagObjs.splice(index, 1);
        },
        loadCSV(e) {
          if (
            this.$refs.file.files[0].type == "text/csv" ||
            this.$refs.file.files[0].type == "application/vnd.ms-excel"
          ) {
            this.isImportContactsButton = true;
            this.importContactFile = this.$refs.file.files[0];
            this.csvFileName = this.$refs.file.files[0].name;
            this.importContactFileError = null;
          } else {
            this.importContactFileError = "Please upload CSV file only.";
            this.$refs.file.value = "";
            this.csvFileName = null;
            this.importEmailMapField = null;
            this.importCompanyMapField = null;
            this.importFirstNameMapField = null;
            this.importLastNameMapField = null;
            this.importAddress1MapField = null;
            this.importAddress2MapField = null;
            this.importCityMapField = null;
            this.importStateMapField = null;
            this.importZipMapField = null;
            this.importCountryMapField = null;
            this.importPhoneNumberMapField = null;
            this.importFacebookProfileMapField = null;
            this.importLinkedinProfileMapField = null;
            this.importTwitterProfileMapField = null;
            this.importCustomFieldMapField = null;
            this.importCSVFieldMapOptions = [];
            this.isImportContactsButton = false;
            return false;
          }
          var vm = this;
          if (window.FileReader) {
            var reader = new FileReader();
            reader.readAsText(e.target.files[0]);
            reader.onload = function (event) {
              var csv = event.target.result;
              vm.csv = vm.csvJSON(csv);
            };
            // reader.onerror = function(evt){
            //     if(evt.target.error.name = "NotReadableError"){
            //         alert("Cann't read this file!");
            //     }
            // };
          } else {
            alert("File Reader are not supported in this browser!");
          }
        },
        sortBy: function (key) {
          this.sortKey = key;
          this.sortOrders[key] = this.sortOrders[key] * -1;
        },
        csvJSON(csv) {
          var vm = this;
          var lines = csv.split("\n");
          var result = [];
          var headers = lines[0].split(",");
          // vm.importCSVFieldMapOptions = lines[0].split(",")
          // console.log(headers)
          for (let i = 0; i < headers.length; i++) {
            vm.importCSVFieldMapOptions.push({
              id: headers[i],
              field: headers[i],
            });
          }
          // console.log(vm.importCSVFieldMapOptions)
          lines[0].split(",").forEach(function (key) {
            vm.sortOrders[key] = 1;
          });
    
          lines.map(function (line, indexLine) {
            if (indexLine < 1) return;
    
            var obj = {};
            var currentline = line.split(",");
    
            headers.map(function (header, indexHeader) {
              obj[header] = currentline[indexHeader];
            });
    
            result.push(obj);
          });
    
          result.pop();
    
          return result;
        },
        closeImportCvsPopup() {
          this.importContactActive = false;
          this.importContactFileError = null;
          this.$refs.file.value = "";
          (this.importContactFile = ""), (this.csvFileName = null);
          this.importEmailMapField = null;
          this.importCompanyMapField = null;
          this.importFirstNameMapField = null;
          this.importLastNameMapField = null;
          this.importAddress1MapField = null;
          this.importAddress2MapField = null;
          this.importCityMapField = null;
          this.importStateMapField = null;
          this.importZipMapField = null;
          this.importCountryMapField = null;
          this.importPhoneNumberMapField = null;
          this.importFacebookProfileMapField = null;
          this.importLinkedinProfileMapField = null;
          this.importTwitterProfileMapField = null;
          this.importCustomFieldMapField = null;
          this.importCSVFieldMapOptions = [];
          this.importContactsTags = [];
          this.isImportContactsButton = false;
          this.csv = null;
        },
        addListOfContacts() {
          let addListOfContactsParams;
          let fieldMap;
    
          fieldMap = [
            {
              Field_Name: "Email",
              Import_Sheet_Field_Name: this.importEmailMapField,
            },
            {
              Field_Name: "Company",
              Import_Sheet_Field_Name: this.importCompanyMapField,
            },
            {
              Field_Name: "FirstName",
              Import_Sheet_Field_Name: this.importFirstNameMapField,
            },
            {
              Field_Name: "LastName",
              Import_Sheet_Field_Name: this.importLastNameMapField,
            },
            {
              Field_Name: "StreetAddress",
              Import_Sheet_Field_Name: this.importAddress1MapField,
            },
            {
              Field_Name: "Address2",
              Import_Sheet_Field_Name: this.importAddress2MapField,
            },
            {
              Field_Name: "City",
              Import_Sheet_Field_Name: this.importCityMapField,
            },
            {
              Field_Name: "State",
              Import_Sheet_Field_Name: this.importStateMapField,
            },
            {
              Field_Name: "Zip",
              Import_Sheet_Field_Name: this.importZipMapField,
            },
            {
              Field_Name: "Country",
              Import_Sheet_Field_Name: this.importCountryMapField,
            },
            {
              Field_Name: "Phone",
              Import_Sheet_Field_Name: this.importPhoneNumberMapField,
            },
            {
              Field_Name: "FacebookProfile",
              Import_Sheet_Field_Name: this.importFacebookProfileMapField,
            },
            {
              Field_Name: "LinkedinProfile",
              Import_Sheet_Field_Name: this.importLinkedinProfileMapField,
            },
            {
              Field_Name: "TwitterProfile",
              Import_Sheet_Field_Name: this.importTwitterProfileMapField,
            },
            {
              Field_Name: "CustomField",
              Import_Sheet_Field_Name: this.importCustomFieldMapField,
            },
          ];
    
          function getFile(file) {
            var reader = new FileReader();
            return new Promise((resolve, reject) => {
              reader.onerror = () => {
                reader.abort();
                reject(new Error("Error parsing file"));
              };
              reader.onload = function () {
                let bytes = Array.from(new Uint8Array(this.result));
                let base64StringFile = btoa(
                  bytes.map((item) => String.fromCharCode(item)).join("")
                );
                resolve({
                  bytes: bytes,
                  base64StringFile: base64StringFile,
                  fileName: file.name,
                  fileType: file.type,
                });
              };
              reader.readAsArrayBuffer(file);
            });
          }
    
          getFile(this.$refs.file.files[0]).then((customJsonFile) => {
            addListOfContactsParams = {
              ContactImportMap: fieldMap,
              Tags: this.importContactsTags,
              ContactImportFile: customJsonFile.base64StringFile,
            };
    
            // console.log(addListOfContactsParams)
            this.$vs.loading();
            this.axios
              .post(
                "/ws/EmailMarketingContact/ImportContactList",
                addListOfContactsParams
              )
              .then(() => {
                this.closeImportCvsPopup();
                this.$vs.notify({
                  title: "Success",
                  text: "File uploaded successfully.",
                  color: "success",
                  iconPack: "feather",
                  icon: "icon-check",
                  position: "top-right",
                  time: 4000,
                });
                this.$vs.loading.close();
              })
              .catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                  title: "Error",
                  text: e.response.data.Errors[0].Message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  position: "top-right",
                  time: 4000,
                });
              });
          });
        },
        methodFromParentGetCustomer(Id) {
          this.getCustomer(Id);
        },
        backFromContactDetails() {
          this.activeContact = false;
          this.contactDetails = null;
          this.addContactNote = null;
          this.isOpenNote = false;
        },
        getCustomer(id) {
          this.activeContact = true;
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContact/GetContact?id=" + id)
            .then((response) => {
              let data = response.data;
              this.contactDetails = data;
              for (let j = 0; j < this.contactDetails.CustomFields.length; j++) {
                if (
                  this.contactDetails.CustomFields[j].Field_Type.Field_Type ==
                    "dropdown" ||
                  this.contactDetails.CustomFields[j].Field_Type.Field_Type ==
                    "radio"
                ) {
                  for (
                    let k = 0;
                    k < this.contactDetails.CustomFields[j].Options.length;
                    k++
                  ) {
                    if (
                      this.contactDetails.CustomFields[j].Current_Value ==
                      this.contactDetails.CustomFields[j].Options[k].Id
                    ) {
                      this.contactDetails.CustomFields[
                        j
                      ].Current_Value = this.contactDetails.CustomFields[j].Options[
                        k
                      ].Possible_Value;
                    }
                  }
                }
              }
              this.addContactTagOptions = [];
              if (this.contactDetails.Tags) {
                for (let i = 0; i < this.tags.length; i++) {
                  if (!this.contactDetails.Tags.includes(this.tags[i].Id)) {
                    this.addContactTagOptions.push(this.tags[i]);
                  }
                }
              } else {
                this.addContactTagOptions = this.tags;
              }
              this.getContactEmailPerformance(id);
              this.getContactCustomerJourney(id);
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        getContactEmailPerformance(id) {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContact/GetContactEmailPerformance?id=" + id)
            .then((response) => {
              let data = response.data;
              this.openRateChart.series = [data.Open_Rate];
              this.clickRateChart.series = [data.Click_Rate];
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        getContactCustomerJourney(id) {
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContact/GetContactCustomerJourney?id=" + id)
            .then(() => {
              // let data = response.data;
              let modifiedData = [];
              let dateArr = [];
              for (let i = 0; i < this.tempCustomerJourney.length; i++) {
                let date = moment(this.tempCustomerJourney[i].Date_Short).format(
                  "MMMM DD, YYYY"
                );
                if (!dateArr.includes(date)) {
                  dateArr.push(date);
                  modifiedData.push({
                    Date: date,
                    Data: [this.tempCustomerJourney[i]],
                  });
                } else {
                  for (let j = 0; j < modifiedData.length; j++) {
                    if (date == modifiedData[j].Date) {
                      modifiedData[j].Data.push(this.tempCustomerJourney[i]);
                    }
                  }
                }
              }
              this.customerJourney = modifiedData;
              this.customerJourneyStore = modifiedData;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        contactAddNote() {
          let contactAddNoteParams = {
            ContactId: this.contactDetails.Id,
            Note: this.addContactNote,
          };
          this.$vs.loading();
          this.axios
            .post("/ws/EmailMarketingContact/ContactAddNote", contactAddNoteParams)
            .then(() => {
              this.isOpenNote = false;
              this.addContactNote = null;
              this.getCustomer(this.contactDetails.Id);
              this.$vs.notify({
                title: "Success",
                text: "Contact note created successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
        contactAddTag() {
          let contactAddTagParams = {
            ContactId: this.contactDetails.Id,
            TagId: this.addContactTag,
          };
          this.$vs.loading();
          this.axios
            .post("/ws/EmailMarketingContact/ContactAddTag", contactAddTagParams)
            .then(() => {
              this.addContactTag = null;
              this.getCustomer(this.contactDetails.Id);
              this.$vs.notify({
                title: "Success",
                text: "Tag added successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
        filterCustomerJourneyByType() {
          if (this.selectedCustomerJourneyTouchPointType) {
            let tempJourney = [];
            this.customerJourney = this.customerJourneyStore;
            for (let i = 0; i < this.customerJourney.length; i++) {
              tempJourney.push({ Date: this.customerJourney[i].Date, Data: [] });
              for (let j = 0; j < this.customerJourney[i].Data.length; j++) {
                if (
                  this.customerJourney[i].Data[j].Touchpoint_Type ==
                  this.selectedCustomerJourneyTouchPointType
                ) {
                  tempJourney[i].Data.push(this.customerJourney[i].Data[j]);
                }
              }
            }
            for (let k = 0; k < tempJourney.length; k++) {
              if (tempJourney[k].Data.length < 1) {
                tempJourney.splice(k, 1);
              }
            }
            this.customerJourney = tempJourney;
          } else {
            this.customerJourney = this.customerJourneyStore;
          }
        },
        deleteContact() {
          this.$vs.loading();
          this.axios
            .get(
              "/ws/EmailMarketingContact/DeleteContact?id=" + this.contactDetails.Id
            )
            .then(() => {
              this.backFromContactDetails();
              let listContactsParams = {
                Fitler_Type: "",
                Filter_Segment: "",
                Filter_Tag: [],
                Filter_Search_Value: "",
                Order_By: "Email_Address",
                Order_By_Type: "asc",
                Number_Per_Page: 0,
                Skip_Number_Of_Pages: 0,
              };
              this.listContacts(listContactsParams);
              this.deleteContactPopupActive = false;
              this.$vs.notify({
                title: "Success",
                text: "Contact deleted successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
        cancelCustomerEdit() {
          this.editContactPopupActive = false;
          this.contactDetailsEdit = null;
        },
        getCustomerEditable(id) {
            this.editContactPopupActive = true;
          this.$vs.loading();
          this.axios
            .get("/ws/EmailMarketingContact/GetContact?id=" + id)
            .then((response) => {
              let data = response.data;
              this.contactDetailsEdit = data;
              for (
                let j = 0;
                j < this.contactDetailsEdit.CustomFields.length;
                j++
              ) {
                if (
                  this.contactDetailsEdit.CustomFields[j].Field_Type.Field_Type ==
                  "dropdown"
                ) {
                  for (
                    let k = 0;
                    k < this.contactDetailsEdit.CustomFields[j].Options.length;
                    k++
                  ) {
                    if (
                      this.contactDetailsEdit.CustomFields[j].Current_Value ==
                      this.contactDetailsEdit.CustomFields[j].Options[k].Id
                    ) {
                      this.contactDetailsEdit.CustomFields[
                        j
                      ].Current_Value = this.contactDetailsEdit.CustomFields[
                        j
                      ].Options[k].Id;
                    }
                  }
                }
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
            });
        },
        updateContact() {
          this.$vs.loading();
          this.axios
            .post(
              "/ws/EmailMarketingContact/UpdateContact",
              this.contactDetailsEdit
            )
            .then(() => {
              this.getCustomer(this.contactDetails.Id);
              this.cancelCustomerEdit();
              let listContactsParams = {
                Fitler_Type: "",
                Filter_Segment: "",
                Filter_Tag: [],
                Filter_Search_Value: "",
                Order_By: "Email_Address",
                Order_By_Type: "asc",
                Number_Per_Page: 0,
                Skip_Number_Of_Pages: 0,
              };
              this.listContacts(listContactsParams);
              this.$vs.notify({
                title: "Success",
                text: "Contact updated successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        },
        viewFilter(data, type) {
          if (type == "tag") {
            this.filterSelectedTagObjs = [data];
            this.mainActivetab = 1;
            this.contactsFilterByTag();
          } else {
            this.filterSelectedSegmentObj = data;
            this.mainActivetab = 1;
            this.contactsFilterBySegment();
          }
        },
        editSegmentContact(id) {
          this.mainActivetab = 2;
          this.getSegment(id);
        },
      },
      computed: {
        paginationPageSize() {
          if (this.gridOptions.api)
            return this.gridOptions.api.paginationGetPageSize();
          else return 5;
        },
        totalPages() {
          if (this.gridOptions.api)
            return this.gridOptions.api.paginationGetTotalPages();
          else return 4;
        },
        currentPage: {
          get() {
            if (this.gridOptions.api)
              return this.gridOptions.api.paginationGetCurrentPage() + 1;
            else return 1;
          },
          set(val) {
            this.gridOptions.api.paginationGoToPage(val - 1);
          },
        },
        isDisableAddContactTags() {
          if (
            this.contactDetails.Tags &&
            this.tags.length == this.contactDetails.Tags.length
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      watch: {
        "$store.state.windowWidth"(val) {
          if (val <= 767) {
            this.gridOptions.columnApi.setColumnPinned("Id", null);
            this.gridOptions.columnApi.setColumnPinned("emailAddress", null);
          } else {
            this.gridOptions.columnApi.setColumnPinned("Id", "left");
            this.gridOptions.columnApi.setColumnPinned("emailAddress", "left");
          }
        },
      }
}