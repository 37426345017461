<template>
    <div>
        <div class="contacts-info">
          <div class="contact-table">
            <!-- Tag, Segment Filter and Export Contacts -->
            <div class="filter">
              <div class="filter-option">
                <v-select
                  v-model="filterSelectedTagObjs"
                  :options="tagFilterOptions"
                  :label="tagFilterOptionLabel"
                  class="multiple-select"
                  multiple
                  :placeholder="tagFilterPlaceholder"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  @input="$emit('inputTag')"
                />
                <v-select
                  v-model="filterSelectedSegmentObj"
                  :options="segmentFilterOptions"
                  :label="segmentFilterOptionLabel"
                  class="multiple-select"
                  :placeholder="segmentFilterPlaceholder"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  @input="$emit('inputSegment')"
                />
              </div>
              <vs-button
                radius
                type="filled"
                class="bg-actionbutton small-btn"
                @click="$emit('clickToExportCSVContacts')"
                >{{ LabelConstant.buttonLabelExportContacts }}</vs-button
              >
              <!-- Add Contacts Button -->
              <vs-dropdown>
                <a class="flex items-center" href="#">
                  Add Contacts <i class="material-icons"> expand_more </i>
                </a>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    ><a @click="$emit('clickToUploadContacts')"> Upload CSV </a>
                  </vs-dropdown-item>
                  <vs-dropdown-item>
                    <a @click="$emit('clickToUploadManualContact')"> Manual Add </a>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
            <!-- Tag filter status bar -->
            <div class="after-filter" v-if="filterSelectedTagObjs.length > 0">
              <h3>
                Filterd by tags
                <span>{{ contactsData.length }} contacts contain</span>
              </h3>
              <ul>
                <li v-for="(filterSelectedTagObjs, index) in filterSelectedTagObjs" :key="index">
                  <span class="tag-bg">
                    {{ filterSelectedTagObjs.Tag_Name }}
                    <a href="#" @click="$emit('clickToRemoveFilterTag', index)">
                      <feather-icon icon="XIcon"></feather-icon> 
                    </a>
                  </span>
                </li>
              </ul>
            </div>
            <!-- Segment filter status bar -->
            <div class="after-filter" v-if="filterSelectedSegmentObj">
              <h3>
                {{ filterSelectedSegmentObj.Name }}
                <span>{{ contactsData.length }} contacts Included</span>
              </h3>
              <ul>
                <li>
                  <a href="#">
                    <span class="tag-bg" @click="$emit('clickToEditSegmentContact', filterSelectedSegmentObj.Id)">Edit 
                      <feather-icon icon="Edit2Icon"></feather-icon>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- Action options for Selected Contacts -->
            <div class="table-filter mb-4 hidden">
              <div class="flex float-left items-center flex-wrap w-full lg:w-auto">
                <h3><span>3</span> Contacts Included</h3>
                <v-select
                  label="Tag_Name"
                  placeholder="Add or Remove Tags"
                  :options="tagFilterOptions"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <vs-dropdown>
                  <a class="flex items-center" href="#">
                    Actions <i class="material-icons"> expand_more </i>
                  </a>
                  <vs-dropdown-menu>
                    <vs-dropdown-item>
                      <a href="#" @click="$emit('clickToResubscribeSelectedContact')">
                        Resubsribe
                      </a>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <a href="#" @click="$emit('clickToUnsubscribeSelectedContact')">
                        Unsubsribe
                      </a>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <a href="#" @click="$emit('clickToDeleteSelectedContact')">
                        Delete
                      </a>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
              <vs-button
                radius
                type="filled"
                class="bg-actionbutton small-btn lg:mt-0 mt-4"
                >{{ LabelConstant.buttonLabelSendCampaign }}</vs-button
              >
            </div>
            <!-- Contacts table per page records dropdown and search textbox -->
            <div class="flex flex-wrap justify-between items-center w-full">
              <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                  >
                    <span class="mr-2"
                      >{{
                        currentPage * paginationPageSize -
                        (paginationPageSize - 1)
                      }}
                      -
                      {{
                        contactsData.length - currentPage * paginationPageSize >
                        0
                          ? currentPage * paginationPageSize
                          : contactsData.length
                      }}
                      of {{ contactsData.length }}</span
                    >
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('clickToSetPageSizeFive')">
                      <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeTen')"
                    >
                      <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeTwenty')"
                    >
                      <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeTwentyFive')"
                    >
                      <span>25</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeFifty')"
                    >
                      <span>50</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeHundred')"
                    >
                      <span>100</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeTwoHundred')"
                    >
                      <span>200</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeThreeHundred')"
                    >
                      <span>300</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeFourHundred')"
                    >
                      <span>400</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="$emit('clickToSetPageSizeFiveHundred')"
                    >
                      <span>500</span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
              <div
                class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
              >
                <vs-input
                  class="mb-4 md:mb-0 mr-4"
                  v-model="searchQuery"
                  @input="$emit('clickToUpdateSearchQuery')"
                  placeholder="Search..."
                />
              </div>
            </div>
            <!-- Contacts table -->
            <ag-grid-vue
              :ref="refName"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="contactsData"
              :rowSelection="rowSelection"
              :colResizeDefault="colResizeDefault"
              :animateRows="animateRows"
              :floatingFilter="floatingFilter"
              :pagination="pagination"
              :paginationPageSize="paginationPageSizeGrid"
              :suppressPaginationPanel="suppressPaginationPanel"
              :frameworkComponents="frameworkComponents"
              :context="context"
              :gridReady="gridReady"
              :enableRtl="enableRtl"
            >
            </ag-grid-vue>
            <vs-pagination
              class="ag-grid-pagination-area"
              :total="totalPages"
              :max="maxPageNumbers"
              v-model="currentPage"
            />
          </div>
        </div>
        <!-- Contacts detail Info section -->
        <div class="contact-inner-info" :class="{ opencontactinfo: activeContact }">
          <div class="vx-row" v-if="contactDetails != null">
            <div class="w-full vx-col">
              <span class="back-main" @click="$emit('clickToBackFromContactDetails')">
                <feather-icon icon="ArrowLeftIcon" /> Back
              </span>
            </div>
            <!-- Contact Image and Name, Customer Journey -->
            <div class="lg:w-2/5 w-full vx-col">
              <!-- Contact Image and Name -->
              <vx-card class="mb-8">
                <div class="customer-profile">
                  <vs-dropdown>
                    <a class="flex items-center" href="#">
                      Actions <i class="material-icons"> expand_more </i>
                    </a>
                    <vs-dropdown-menu>
                      <vs-dropdown-item><a> Send Message </a></vs-dropdown-item>
                      <vs-dropdown-item><a> Unsubscribe </a></vs-dropdown-item>
                      <vs-dropdown-item>
                        <a @click="$emit('clickToDeleteContact')">
                          Delete Contact
                        </a>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                  <div class="customer-img">
                    <span>
                      <img src="../../../../src/assets/images/avatar-s-11.png" />
                    </span>
                  </div>
                  <h2>
                    {{ contactDetails.First_Name + " " + contactDetails.Last_Name }}
                  </h2>
                  <!-- <span class="import-date" >Added via import on 9/10/2020 </span> -->
                  <div class="multiple-tag-added">
                    <v-select
                      :options="tagFilterOptions"
                      v-model="contactDetails.Tags"
                      :reduce="(tagFilterOptions) => tagFilterOptions.Id"
                      :label="tagFilterOptionLabel"
                      class="multiple-select"
                      multiple
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      :disabled="true"
                    />
                  </div>
                  <div class="add-tag not-add-plus">
                    <div class="flex items-center justify-between">
                      <h3>Tags</h3>
                      <vs-button
                        :disabled="isDisableAddContactTags"
                        icon-pack="feather"
                        type="filled"
                        class="bg-actionbutton small-btn"
                        @click="$emit('clickToContactAddTag')"
                        >{{ LabelConstant.buttonLabelAdd }}</vs-button
                      >
                    </div>
                    <v-select
                      :options="addContactTagOptions"
                      v-model="addContactTag"
                      :reduce="(options) => options.Id"
                      label="Tag_Name"
                      class="multiple-select"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      :clearable="false"
                    >
                      <span slot="no-options">All tags are selected.</span>
                    </v-select>
                  </div>
                </div>
              </vx-card>
              <!-- Customer Journey -->
              <vx-card class="mb-8">
                <div class="journey-details">
                  <h3>Customer Journey</h3>
                  <div class="filter-item">
                    <v-select
                      v-model="selectedCustomerJourneyTouchPointType"
                      :options="customerJourneyTouchPointType"
                      :reduce="(type) => type.name"
                      label="label"
                      @input="$emit('clickForFilterCustomerJourneyByType')"
                      class="multiple-select"
                      placeholder="Filter"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    />
                  </div>
                  <ul
                    class="journey-info"
                    v-for="(journeyDate, i) in customerJourney"
                    :key="i"
                  >
                    <li>
                      <h4>{{ journeyDate.Date }}</h4>
                    </li>
                    <li v-for="(journeyTime, j) in journeyDate.Data" :key="j">
                      <span class="date-journey"
                        ><small>{{ journeyTime.Time }}</small></span
                      >
                      <span class="journey-icon">
                        <feather-icon
                          icon="GlobeIcon"
                          v-if="journeyTime.Touchpoint_Type == 'Website Visits'"
                        />
                        <feather-icon
                          icon="PhoneIcon"
                          v-if="journeyTime.Touchpoint_Type == 'Phone Calls'"
                        />
                        <feather-icon
                          icon="MailIcon"
                          v-if="journeyTime.Touchpoint_Type == 'Clicks'"
                        />
                        <feather-icon
                          icon="ExternalLinkIcon"
                          v-if="journeyTime.Touchpoint_Type == 'Opens'"
                        />
                        <feather-icon
                          icon="SendIcon"
                          v-if="journeyTime.Touchpoint_Type == 'Sends'"
                        />
                        <feather-icon
                          icon="PlusCircleIcon"
                          v-if="journeyTime.Touchpoint_Type == 'Notes'"
                        />
                      </span>
                      <div class="journey-data">
                        <h3>{{ journeyTime.Message1 }}</h3>
                        <a href="#" class="text-actionbutton">{{ journeyTime.Message2 }}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </vx-card>
            </div>
            <!-- Email Performance, Contact Details, Contact Notes -->
            <div class="lg:w-3/5 w-full vx-col">
              <!-- Email Performance -->
              <vx-card class="mb-8 radial-chart">
                <div class="vx-row">
                  <div class="w-full vx-col">
                    <h3>Email Performance</h3>
                  </div>
                  <div class="sm:w-1/2 w-full vx-col">
                    <vue-apex-charts
                      type="radialBar"
                      height="150"
                      :options="clickRateChart.chartOptions"
                      :series="clickRateChart.series"
                    ></vue-apex-charts>
                    <h4>Click Rate</h4>
                  </div>
                  <div class="sm:w-1/2 w-full vx-col">
                    <vue-apex-charts
                      type="radialBar"
                      height="150"
                      :options="openRateChart.chartOptions"
                      :series="openRateChart.series"
                    ></vue-apex-charts>
                    <h4>Open Rate</h4>
                  </div>
                </div>
              </vx-card>
              <!-- Contact Details -->
              <vx-card class="mb-8">
                <h3 class="flex items-center justify-between">
                  Contact Details
                  <vs-button
                    @click="$emit('clickForEditContact', contactDetails.Id)"
                    class="float-right"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-edit"
                    type="filled"
                  ></vs-button>
                </h3>
                <div class="contact-details-list">
                  <ul>
                    <li>
                      <p>Email Address</p>
                      <span>{{ contactDetails.Email_Address }}</span>
                    </li>
                    <li>
                      <p>Company</p>
                      <span>{{ contactDetails.Company }}</span>
                    </li>
                    <li>
                      <p>First Name</p>
                      <span>{{ contactDetails.First_Name }}</span>
                    </li>
                    <li>
                      <p>Last Name</p>
                      <span>{{ contactDetails.Last_Name }}</span>
                    </li>
                    <li>
                      <p>Address</p>
                      <span>{{ contactDetails.Address1 + ", " + contactDetails.Address1 }}</span>
                    </li>
                    <li>
                      <p>Phone Number</p>
                      <span>{{ contactDetails.Phone }}</span>
                    </li>
                    <li>
                      <p>Facebook Profile</p>
                      <span>{{ contactDetails.Facebook_Profile }}</span>
                    </li>
                    <li>
                      <p>Linkedin Profile</p>
                      <span>{{ contactDetails.LinkedIn_Profile }}</span>
                    </li>
                    <li>
                      <p>Twitter Profile</p>
                      <span>{{ contactDetails.Twitter_Profile }}</span>
                    </li>
                    <li
                      v-for="(customField, index) in contactDetails.CustomFields"
                      :key="index"
                      v-if="customField.Field_Type.Field_Type != 'image'"
                    >
                      <p>{{ customField.Field_Label }}</p>
                      <span>{{ customField.Current_Value }}</span>
                      <!-- <img v-if="customField.Field_Type.Field_Type == 'image'" :src="'data:image/png;base64, ' + customField.Current_Value" /> -->
                    </li>
                  </ul>
                </div>
              </vx-card>
              <!-- Contact Notes -->
              <vx-card>
                <h3>Contact Notes</h3>
                <ul class="add-edit-note">
                  <li class="add-icon">
                    <vx-tooltip text="Add Note">
                      <a v-on:click="isOpenNote = !isOpenNote">
                        <feather-icon
                          v-show="!isOpenNote"
                          icon="PlusIcon"
                          class="text-primary"
                        ></feather-icon>
                        <feather-icon
                          v-show="isOpenNote"
                          icon="MinusIcon"
                          class="text-primary"
                        ></feather-icon>
                      </a>
                    </vx-tooltip>
                  </li>
                  <!-- <li>
                      <a href="#">
                        <feather-icon
                          icon="EditIcon"
                          class="text-primary"
                        ></feather-icon>
                      </a>
                    </li> -->
                </ul>
                <div class="write-note" v-show="isOpenNote">
                  <vs-textarea
                    placeholder="Write youe notes here"
                    class="form-textarea mb-0 w-full"
                    v-model="addContactNote"
                  />
                  <vs-button
                    type="filled"
                    class="float-right bg-actionbutton small-btn"
                    @click="$emit('clickToContactAddNote')"
                    >{{ LabelConstant.buttonLabelAddNote }}</vs-button
                  >
                </div>
                <div
                  class="add-note"
                  v-for="(note, index) in contactDetails.Notes"
                  :key="index"
                >
                  <span>{{ note.Date_Created | contactCreatedDateFormat }}</span>
                  <h4>Added By {{ note.Created_By }}</h4>
                  <p>{{ note.Note }}</p>
                </div>
              </vx-card>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import VueApexCharts from "vue-apexcharts";
import VxCard from "@/components/vx-card/VxCard";
import moment from "moment";

export default ({
    components: {
      VxCard,
      vSelect,
      AgGridVue,
      VueApexCharts,
    },
    props:{
      tagFilterOptions:{
        type: Array,
        required: true
      },
      filterSelectedTagObjsValue:{
        type: Array,
        required: true
      },
      tagFilterOptionLabel:{
        type: String,
        required: true
      },
      tagFilterPlaceholder:{
        type: String,
        required: true
      },
      segmentFilterOptions:{
        type: Array,
        required: true
      },
      filterSelectedSegmentObjValue:{
        required: true
      },
      segmentFilterOptionLabel:{
        type: String,
        required: true
      },
      segmentFilterPlaceholder:{
        type: String,
        required: true
      },
      contactsData:{
        type: Array,
        required: true
      },
      currentPageValue:{
        type: Number,
        required:true
      },
      paginationPageSize:{
        type: Number,
        required:true
      },
      searchQueryValue:{
        required: true
      },
      refName:{
        required: true
      },
      gridOptions:{
        required: true
      },
      columnDefs:{
        required: true
      },
      defaultColDef:{
        required: true
      },
      rowData:{
        required: true
      },
      rowSelection:{
        required: true
      },
      colResizeDefault:{
        required: true
      },
      animateRows:{
        required: true
      },
      floatingFilter:{
        required: true
      },
      pagination:{
        required: true
      },
      paginationPageSizeGrid:{
        required: true
      },
      suppressPaginationPanel:{
        required: true
      },
      frameworkComponents:{
        required: true
      },
      context:{
        required: true
      },
      gridReady:{
        required: true
      },
      enableRtl:{
        required: true
      },
      totalPages:{
        required: true
      },
      maxPageNumbers:{
        required: true
      },
      activeContact:{
        type: Boolean,
        required: true
      },
      contactDetails:{
        required: true
      },
      isDisableAddContactTags:{
        required: true
      },
      addContactTagOptions:{
        required: true
      },
      addContactTagValue:{
        required: true
      },
      customerJourneyTouchPointType:{
        required: true
      },
      selectedCustomerJourneyTouchPointTypeValue:{
        required: true
      },
      customerJourney:{
        required: true
      },
      clickRateChart:{
        required: true
      },
      openRateChart:{
        required: true
      },
      isOpenNoteValue:{
        required: true
      },
      addContactNoteValue:{
        required: true
      }
    },
    computed: {
      filterSelectedTagObjs: {
        get() { return this.filterSelectedTagObjsValue },
        set(filterSelectedTagObjsValue) { this.$emit('filterSelectedTagObjsInput', filterSelectedTagObjsValue) }
      },
      filterSelectedSegmentObj: {
        get() { return this.filterSelectedSegmentObjValue },
        set(filterSelectedSegmentObjValue) { this.$emit('filterSelectedSegmentObjInput', filterSelectedSegmentObjValue) }
      },
      searchQuery: {
        get() { return this.searchQueryValue },
        set(searchQueryValue) { this.$emit('searchQueryInput', searchQueryValue) }
      },
      currentPage: {
        get() { return this.currentPageValue },
        set(currentPageValue) { this.$emit('currentPageInput', currentPageValue) }
      },
      addContactTag: {
        get() { return this.addContactTagValue },
        set(addContactTagValue) { this.$emit('addContactTagInput', addContactTagValue) }
      },
      selectedCustomerJourneyTouchPointType: {
        get() { return this.selectedCustomerJourneyTouchPointTypeValue },
        set(selectedCustomerJourneyTouchPointTypeValue) { this.$emit('selectedCustomerJourneyTouchPointTypeInput', selectedCustomerJourneyTouchPointTypeValue) }
      },
      isOpenNote: {
        get() { return this.isOpenNoteValue },
        set(isOpenNoteValue) { this.$emit('isOpenNoteInput', isOpenNoteValue) }
      },
      addContactNote: {
        get() { return this.addContactNoteValue },
        set(addContactNoteValue) { this.$emit('addContactNoteInput', addContactNoteValue) }
      }
    },
    filters: {
      contactCreatedDateFormat: function (value) {
        return moment(value).format("MMMM DD, YYYY");
      },
    },
})
</script>
