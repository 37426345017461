<template>
  <div>
    <vs-tabs class="contact-tab mt-8" v-model="mainActivetab">
      <!-- Overview -->
      <vs-tab label="Overview" icon-pack="feather" icon="icon-eye">
        <overview-tab
          :totalContacts="totalContacts"
          :SubscribedContacts="SubscribedContacts"
          :unSubscribedContacts="unSubscribedContacts"
          :segmentCreated="segmentCreated"
          @changeTab="changeTab"
          :campaignPerformance="campaignPerformance"
          :radialDasktopChart="radialDasktopChart"
          :radialMobileChart="radialMobileChart"
          :totalClientDesktopPercentage="totalClientDesktopPercentage"
          :topClientDesktop="topClientDesktop"
          :totalClientMobilePercentage="totalClientMobilePercentage"
          :topClientMobile="topClientMobile"
          :chartData="chartData"
          :chartOptions="chartOptions"
          :countryLocation="countryLocation"
          :maxRecordPerPage="maxRecordPerPage"
        ></overview-tab>
      </vs-tab>

      <!-- Contacts -->
      <vs-tab label="Contacts" icon-pack="feather" icon="icon-phone">
        <contacts-tab
          :tagFilterOptions="tags"
          :filterSelectedTagObjsValue="filterSelectedTagObjs"
          @filterSelectedTagObjsInput="(newFilterSelectedTagObjs) => {filterSelectedTagObjs = newFilterSelectedTagObjs;}"
          :tagFilterOptionLabel="'Tag_Name'"
          :tagFilterPlaceholder="'Filter By Tags'"
          @inputTag="contactsFilterByTag()"

          :segmentFilterOptions="segmentList"
          :filterSelectedSegmentObjValue="filterSelectedSegmentObj"
          @filterSelectedSegmentObjInput="(newFilterSelectedSegmentObj) => {filterSelectedSegmentObj = newFilterSelectedSegmentObj;}"
          :segmentFilterOptionLabel="'Name'"
          :segmentFilterPlaceholder="'Filter By Segments'"
          @inputSegment="contactsFilterBySegment()"

          @clickToExportCSVContacts="gridOptions.api.exportDataAsCsv()"

          @clickToUploadContacts="importContactActive = true"
          @clickToUploadManualContact="openAddContactPopup()"

          :contactsData="contactsData"

          @clickToRemoveFilterTag="removeFilterTag"

          @clickToEditSegmentContact="editSegmentContact"

          @clickToResubscribeSelectedContact="resubscribeSelectedContact = true"
          @clickToUnsubscribeSelectedContact="unsubscribeSelectedContact = true"
          @clickToDeleteSelectedContact="deleteSelectedContact = true"

          :currentPage="currentPage"
          :paginationPageSize="paginationPageSize"

          @clickToSetPageSizeFive="gridApi.paginationSetPageSize(5)"
          @clickToSetPageSizeTen="gridApi.paginationSetPageSize(10)"
          @clickToSetPageSizeTwenty="gridApi.paginationSetPageSize(20)"
          @clickToSetPageSizeTwentyFive="gridApi.paginationSetPageSize(25)"
          @clickToSetPageSizeFifty="gridApi.paginationSetPageSize(50)"
          @clickToSetPageSizeHundred="gridApi.paginationSetPageSize(100)"
          @clickToSetPageSizeTwoHundred="gridApi.paginationSetPageSize(200)"
          @clickToSetPageSizeThreeHundred="gridApi.paginationSetPageSize(300)"
          @clickToSetPageSizeFourHundred="gridApi.paginationSetPageSize(400)"
          @clickToSetPageSizeFiveHundred="gridApi.paginationSetPageSize(500)"

          :searchQueryValue="searchQuery"
          @searchQueryInput="(newSearchQuery) => {searchQuery = newSearchQuery;}"
          @clickToUpdateSearchQuery="updateSearchQuery(searchQuery)"

          :refName="'agGridTable'"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="contactsData"
          :rowSelection="'multiple'"
          :colResizeDefault="'shift'"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSizeGrid="5"
          :suppressPaginationPanel="true"
          :frameworkComponents="frameworkComponents"
          :context="context"
          :gridReady="onGridReady()"
          :enableRtl="$vs.rtl"

          :totalPages="totalPages"
          :maxPageNumbers="maxPageNumbers"
          :currentPageValue="currentPage"
          @currentPageInput="(newCurrentPage) => {currentPage = newCurrentPage;}"

          :activeContact="activeContact"
          :contactDetails="contactDetails"
          @clickToBackFromContactDetails="backFromContactDetails()"
          @clickToDeleteContact="deleteContactPopupActive = true"
          :isDisableAddContactTags="contactDetails ? isDisableAddContactTags : false"
          @clickToContactAddTag="contactAddTag()"
          :addContactTagOptions="addContactTagOptions"
          :addContactTagValue="addContactTag"
          @addContactTagInput="(newAddContactTag) => {addContactTag = newAddContactTag;}"
          :customerJourneyTouchPointType="customerJourneyTouchPointType"
          :selectedCustomerJourneyTouchPointTypeValue="selectedCustomerJourneyTouchPointType"
          @selectedCustomerJourneyTouchPointTypeInput="(newSelectedCustomerJourneyTouchPointType) => {selectedCustomerJourneyTouchPointType = newSelectedCustomerJourneyTouchPointType;}"
          @clickForFilterCustomerJourneyByType="filterCustomerJourneyByType()"
          :customerJourney="customerJourney"
          :clickRateChart="clickRateChart"
          :openRateChart="openRateChart"
          @clickForEditContact="getCustomerEditable"
          :isOpenNoteValue="isOpenNote"
          @isOpenNoteInput="(newIsOpenNote) => {isOpenNote = newIsOpenNote;}"
          :addContactNoteValue="addContactNote"
          @addContactNoteInput="(newAddContactNote) => {addContactNote = newAddContactNote;}"
          @clickToContactAddNote="contactAddNote"
        ></contacts-tab>
      </vs-tab>

      <!-- Segments -->
      <vs-tab label="Segments" icon-pack="feather" icon="icon-globe">
        <segments-tab
          @clickToAddSegment="(addSegmentActive = true),(segmentAddEditPopupTitle = 'Add New Segment')"
          :segmentList="segmentList"
          @clickToGetSegment="getSegment"
          @clickToViewFilter="viewFilter"
          :deletableSegmentObjValue="deletableSegmentObj"
          @deletableSegmentObjInput="(newDeletableSegmentObj) => {deletableSegmentObj = newDeletableSegmentObj;}"
          @clickToDeleteSegment="(deleteSegmentActive = true)"
        ></segments-tab>
      </vs-tab>

      <!-- Tags -->
      <vs-tab label="Tags" icon-pack="feather" icon="icon-tag">
        <tags-tab
          @clickToAddTag="addTagActive = true"
          :tags="tags"
          @clickToEditTag="editTag"
          @clickToViewFilter="viewFilter"
          @clickToDeleteTag="deleteTagConfirmation"
        ></tags-tab>
      </vs-tab>
    </vs-tabs>

    <!-- Create New Contact Popup -->
    <vs-popup
      class="popup-width contact-popup tag-info close-open"
      title="Add Contact"
      :active.sync="addContactPopupActive"
    >
      <vs-button
        @click="cancelAddContact()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
      <template>
        <form>
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Email Address"
                v-model="addContactEmailAddress"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Company"
                v-model="addContactCompany"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="First Name"
                v-model="addContactFirstName"
              />
            </div>
            <div class="vx-col w-full md:w-1/2">
              <vs-input
                class="w-full"
                label="Last Name"
                v-model="addContactLastName"
              />
            </div>
            <div class="vx-col w-full mb-2">
              <div class="vx-row">
                <div class="vx-col w-full mb-2">
                  <label class="vs-input--label">Address</label>
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-textarea
                    placeholder="Street Address"
                    v-model="addContactAddress1"
                    class="form-textarea mb-0 w-full"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-textarea
                    placeholder="Address line 2"
                    v-model="addContactAddress2"
                    class="form-textarea mb-0 w-full"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-input
                    class="w-full"
                    placeholder="City"
                    v-model="addContactCity"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-input
                    class="w-full"
                    placeholder="State"
                    v-model="addContactState"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-input
                    class="w-full"
                    placeholder="Zip"
                    v-model="addContactZip"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-input
                    class="w-full"
                    placeholder="Country"
                    v-model="addContactCountry"
                  />
                </div>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Phone Number"
                v-model="addContactPhoneNumber"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Facebook Profile"
                v-model="addContactFacebookProfile"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Linkedin Profile"
                v-model="addContactLinkedinProfile"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Twitter Profile"
                v-model="addContactTwitterProfile"
              />
            </div>
            <div
              class="vx-col w-full mb-6"
              v-if="addContactCustomFields.length > 0"
            >
              <div class="vx-row">
                <div
                  class="vx-col w-full mb-2 md:w-1/2"
                  v-for="(customField, index) in dynamicCustomField"
                  :key="index"
                >
                  <div
                    class="custom-field-input"
                    v-if="
                      customField.Field_Data_Type == 'text' ||
                      customField.Field_Data_Type == 'date'
                    "
                  >
                    <div class="vx-col w-full mb-2">
                      <label class="vs-input--label">{{
                        customField.Field_Label
                      }}</label>
                    </div>
                    <vs-input
                      v-model="addContactCustomFields[index].Current_Value"
                    />
                  </div>
                  <div
                    class="custom-field-input"
                    v-if="customField.Field_Data_Type == 'dropdown'"
                  >
                    <div class="vx-col w-full mb-2">
                      <label class="vs-input--label">{{
                        customField.Field_Label
                      }}</label>
                    </div>
                    <v-select
                      v-model="addContactCustomFields[index].Current_Value"
                      :options="customField.Possible_Values"
                      label="Possible_Value"
                      :reduce="(options) => options.Id"
                      :clearable="false"
                    />
                  </div>
                  <div
                    class="custom-field-input"
                    v-if="customField.Field_Data_Type == 'radio'"
                  >
                    <div class="vx-col w-full mb-2">
                      <label class="vs-input--label">{{
                        customField.Field_Label
                      }}</label>
                    </div>
                    <vs-radio
                      v-model="addContactCustomFields[index].Current_Value"
                      :vs-value="radioField.Id"
                      v-for="(radioField, j) in customField.Possible_Values"
                      :key="j"
                      >{{ radioField.Possible_Value }}</vs-radio
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col w-full mb-6">
              <div class="add-tag">
                <h3>Tags</h3>
                <v-select
                  v-model="addContactTagObj"
                  :options="tags"
                  label="Tag_Name"
                  class="multiple-select"
                  multiple
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
            <div class="vx-col w-full">
              <p>
                The contact you are adding will not be notified. By clicking the
                button below, you confirm this person gave you permission to
                email them.
              </p>
            </div>
          </div>
        </form>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          type="filled"
          class="mr-4"
          color="primary"
          @click="addContact()"
          >{{ LabelConstant.buttonLabelAddContact }}</vs-button
        >
        <vs-button
          color="grey"
          class="grey-btn"
          type="border"
          @click="cancelAddContact()"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Export Contacts from CSV file -->
    <vs-popup
      class="tag-info"
      title="Export Contacts from CSV file"
      :active.sync="importContactActive"
    >
      <div class="vx-row">
        <div class="vx-col w-full mb-10">
          <h2
            class="flex justify-between items-center review-popup-title flex-wrap"
          >
            Select File
            <a
              href="/ExampleImportEmailMarketingContacts.csv"
              download
              class="text-secondary down-file"
              >Download Sample CSV file</a
            >
          </h2>
          <div class="upload-img flex flex-wrap">
            <div class="upload-input">
              <feather-icon
                icon="UploadCloudIcon"
                class="primary-c"
              ></feather-icon>
              <span class="file-text">Upload File</span>
              <input
                type="file"
                ref="file"
                id="csv_file"
                name="csv_file"
                @change="loadCSV($event)"
              />
              <span class="file-name" v-if="csvFileName">{{
                csvFileName
              }}</span>
              <span class="text-danger text-sm" v-if="importContactFileError">{{
                importContactFileError
              }}</span>
            </div>
          </div>
        </div>
        <div class="vx-col w-full">
          <div class="vx-row">
            <div class="vx-col w-full">
              <h2 class="review-popup-title">Map Headers to Fields</h2>
            </div>
          </div>
          <form data-vv-scope="">
            <div class="vx-row">
              <div class="vx-col w-full md:w-1/2 mb-6">
                <label class="vs-input--label">Email</label>
                <v-select
                  v-model="importEmailMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full md:w-1/2 mb-6">
                <label class="vs-input--label">Company</label>
                <v-select
                  v-model="importCompanyMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full md:w-1/2 mb-6">
                <label class="vs-input--label">First Name</label>
                <v-select
                  v-model="importFirstNameMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full md:w-1/2">
                <label class="vs-input--label">Last Name</label>
                <v-select
                  v-model="importLastNameMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full mb-2">
                <div class="vx-row">
                  <div class="vx-col w-full md:w-1/2 mb-4">
                    <label class="vs-input--label">Street Address</label>
                    <v-select
                      v-model="importAddress1MapField"
                      :options="importCSVFieldMapOptions"
                      :reduce="(options) => options.id"
                      :clearable="false"
                      label="field"
                      class="mt-0"
                    />
                  </div>
                  <div class="vx-col w-full md:w-1/2 mb-4">
                    <label class="vs-input--label">Address 2</label>
                    <v-select
                      v-model="importAddress2MapField"
                      :options="importCSVFieldMapOptions"
                      :reduce="(options) => options.id"
                      :clearable="false"
                      label="field"
                      class="mt-0"
                    />
                  </div>
                  <div class="vx-col w-full md:w-1/2 mb-4">
                    <label class="vs-input--label">City</label>
                    <v-select
                      v-model="importCityMapField"
                      :options="importCSVFieldMapOptions"
                      :reduce="(options) => options.id"
                      :clearable="false"
                      label="field"
                      class="mt-0"
                    />
                  </div>
                  <div class="vx-col w-full md:w-1/2 mb-4">
                    <label class="vs-input--label">State</label>
                    <v-select
                      v-model="importStateMapField"
                      :options="importCSVFieldMapOptions"
                      :reduce="(options) => options.id"
                      :clearable="false"
                      label="field"
                      class="mt-0"
                    />
                  </div>
                  <div class="vx-col w-full md:w-1/2 mb-4">
                    <label class="vs-input--label">Zip</label>
                    <v-select
                      v-model="importZipMapField"
                      :options="importCSVFieldMapOptions"
                      :reduce="(options) => options.id"
                      :clearable="false"
                      label="field"
                      class="mt-0"
                    />
                  </div>
                  <div class="vx-col w-full md:w-1/2 mb-4">
                    <label class="vs-input--label">Country</label>
                    <v-select
                      v-model="importCountryMapField"
                      :options="importCSVFieldMapOptions"
                      :reduce="(options) => options.id"
                      :clearable="false"
                      label="field"
                      class="mt-0"
                    />
                  </div>
                </div>
              </div>
              <div class="vx-col w-full md:w-1/2 mb-6">
                <label class="vs-input--label">Phone Number</label>
                <v-select
                  v-model="importPhoneNumberMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full md:w-1/2 mb-6">
                <label class="vs-input--label">Facebook Profile</label>
                <v-select
                  v-model="importFacebookProfileMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full md:w-1/2 mb-6">
                <label class="vs-input--label">Linkedin Profile</label>
                <v-select
                  v-model="importLinkedinProfileMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full md:w-1/2 mb-6">
                <label class="vs-input--label">Twitter Profile</label>
                <v-select
                  v-model="importTwitterProfileMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full md:w-1/2 mb-6">
                <label class="vs-input--label">Custom Field</label>
                <v-select
                  v-model="importCustomFieldMapField"
                  :options="importCSVFieldMapOptions"
                  :reduce="(options) => options.id"
                  :clearable="false"
                  label="field"
                  class="mt-0"
                />
              </div>
              <div class="vx-col w-full mb-6">
                <div class="add-tag">
                  <h3>Tags</h3>
                  <v-select
                    v-model="importContactsTags"
                    :options="tags"
                    :reduce="(options) => options.Id"
                    label="Tag_Name"
                    class="multiple-select"
                    multiple
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="float-right mt-6 mb-8">
        <vs-button
          type="filled"
          class="mr-4"
          color="primary"
          @click="addListOfContacts()"
          :disabled="!isImportContactsButton"
          >{{ LabelConstant.buttonLabelImportContacts }}</vs-button
        >
        <vs-button
          color="grey"
          class="grey-btn"
          type="border"
          @click="closeImportCvsPopup()"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Edit Contact Popup -->
    <vs-popup
      class="popup-width contact-popup tag-info close-open"
      title="Edit Contact"
      :active.sync="editContactPopupActive"
    >
      <vs-button
        @click="cancelCustomerEdit()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
      <template>
        <form v-if="contactDetailsEdit">
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Email Address"
                v-model="contactDetailsEdit.Email_Address"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Company"
                v-model="contactDetailsEdit.Company"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="First Name"
                v-model="contactDetailsEdit.First_Name"
              />
            </div>
            <div class="vx-col w-full md:w-1/2">
              <vs-input
                class="w-full"
                label="Last Name"
                v-model="contactDetailsEdit.Last_Name"
              />
            </div>
            <div class="vx-col w-full mb-2">
              <div class="vx-row">
                <div class="vx-col w-full mb-2">
                  <label class="vs-input--label">Address</label>
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-textarea
                    placeholder="Street Address"
                    v-model="contactDetailsEdit.Address1"
                    class="form-textarea mb-0 w-full"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-textarea
                    placeholder="Address line 2"
                    v-model="contactDetailsEdit.Address2"
                    class="form-textarea mb-0 w-full"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-input
                    class="w-full"
                    placeholder="City"
                    v-model="contactDetailsEdit.City"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-input
                    class="w-full"
                    placeholder="State"
                    v-model="contactDetailsEdit.State"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-input
                    class="w-full"
                    placeholder="Zip"
                    v-model="contactDetailsEdit.Zip"
                  />
                </div>
                <div class="vx-col w-full md:w-1/2 mb-4">
                  <vs-input
                    class="w-full"
                    placeholder="Country"
                    v-model="contactDetailsEdit.Country"
                  />
                </div>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Phone Number"
                v-model="contactDetailsEdit.Phone"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Facebook Profile"
                v-model="contactDetailsEdit.Facebook_Profile"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Linkedin Profile"
                v-model="contactDetailsEdit.LinkedIn_Profile"
              />
            </div>
            <div class="vx-col w-full md:w-1/2 mb-6">
              <vs-input
                class="w-full"
                label="Twitter Profile"
                v-model="contactDetailsEdit.Twitter_Profile"
              />
            </div>
            <div
              class="vx-col w-full mb-6"
              v-if="contactDetailsEdit.CustomFields.length > 0"
            >
              <div class="vx-row">
                <div
                  class="vx-col w-full mb-2 md:w-1/2"
                  v-for="(customField, index) in contactDetailsEdit.CustomFields"
                  :key="index"
                  v-if="customField.Field_Type.Field_Type != 'image'"
                >
                  <div
                    class="custom-field-input"
                    v-if="customField.Field_Type.Field_Type == 'text' || customField.Field_Type.Field_Type == 'date'"
                  >
                    <div class="vx-col w-full mb-2">
                      <label class="vs-input--label">
                        {{ customField.Field_Label }}
                      </label>
                    </div>
                    <vs-input v-model="customField.Current_Value" />
                  </div>
                  <div
                    class="custom-field-input"
                    v-if="customField.Field_Type.Field_Type == 'dropdown'"
                  >
                    <div class="vx-col w-full mb-2">
                      <label class="vs-input--label">
                        {{ customField.Field_Label }}
                      </label>
                    </div>
                    <v-select
                      v-model="customField.Current_Value"
                      :options="customField.Options"
                      label="Possible_Value"
                      :reduce="(options) => options.Id"
                      :clearable="false"
                    />
                  </div>
                  <div
                    class="custom-field-input"
                    v-if="customField.Field_Type.Field_Type == 'radio'"
                  >
                    <div class="vx-col w-full mb-2">
                      <label class="vs-input--label">
                        {{ customField.Field_Label }}
                      </label>
                    </div>
                    <vs-radio
                      v-model="customField.Current_Value"
                      :vs-value="radioField.Id"
                      v-for="(radioField, j) in customField.Options"
                      :key="j"
                      >{{ radioField.Possible_Value }}</vs-radio
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-col w-full mb-6">
              <div class="add-tag">
                <h3>Tags</h3>
                <v-select
                  v-model="contactDetailsEdit.Tags"
                  :options="tags"
                  :reduce="(tag) => tag.Id"
                  label="Tag_Name"
                  class="multiple-select"
                  multiple
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          type="filled"
          class="mr-4"
          color="primary"
          @click="updateContact()"
          >{{ LabelConstant.buttonLabelEditContact }}</vs-button
        >
        <vs-button
          color="grey"
          class="grey-btn"
          type="border"
          @click="cancelCustomerEdit()"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Add,Edit Segment -->
    <vs-popup
      class="popup-width segment-popup tag-info close-open"
      :title="segmentAddEditPopupTitle"
      :active.sync="addSegmentActive"
    >
      <vs-button
        @click="cancelAddSegment()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button
      >
      <template>
        <div class="add-segment">
          <div class="vx-row">
            <div class="vx-col w-full mb-6">
              <vs-input
                class="w-full"
                label="Segment Name"
                v-model="segmentName"
              />
            </div>
            <div class="vx-col w-full contact-condition">
              <v-select
                v-model="matchType"
                :options="matchTypeOptions"
                :reduce="(Options) => Options.name"
                label="label"
                :clearable="false"
              />
              <p>Contact Which of the following conditions</p>
            </div>
            <div
              class="vx-col w-full"
              v-for="(segmentCriteria, index) in segmentSecondListOptions"
              :key="index"
            >
              <div class="segment-details">
                <a
                  class="remove-segment"
                  @click="removeCriteria(index)"
                  v-show="segmentSecondListOptions.length > 1"
                >
                  <feather-icon icon="MinusIcon"></feather-icon>
                </a>
                <div class="select-segment">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mb-2 md:mb-0">
                      <v-select
                        v-model="segmentFirstListSelectedOption[index]"
                        :options="segmentFirstListOptions"
                        label="Criteria_Name"
                        :clearable="false"
                        @input="changeSecondAndThirdListOptions(index)"
                        class="mt-0"
                      />
                    </div>
                    <div class="vx-col md:w-1/4 w-full mb-2 md:mb-0">
                      <v-select
                        v-model="segmentSecondListSelectedOption[index]"
                        :options="segmentSecondListOptions[index]"
                        label="Evaluation_Name"
                        :clearable="false"
                        class="mt-0"
                      />
                    </div>
                    <div class="vx-col md:w-5/12 w-full mb-2 md:mb-0">
                      <v-select
                        v-model="segmentThirdListSelectedOption[index]"
                        :options="segmentThirdListOptions[index]"
                        label="Possible_Value_Label"
                        :clearable="false"
                        @input="changeFourthListOptions(index)"
                        class="mt-0"
                      />
                    </div>
                    <div class="vx-col md:w-5/12 mt-4 w-full mb-2 md:mb-0">
                      <v-select
                        v-if="segmentThirdListSelectedOption[index].Possible_Value_Type == 'list'"
                        v-model="segmentFourthFieldValue[index]"
                        :options="segmentFourthListOptions[index]"
                        label="Key"
                        :clearable="false"
                        class="mt-0"
                      />
                      <vs-input
                        v-if="segmentThirdListSelectedOption[index].Possible_Value_Type == 'input'"
                        class="w-full"
                        v-model="segmentFourthFieldValue[index]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="and-or"
                v-show="segmentSecondListOptions.length - 1 != index"
              >
                <span>and</span>
              </div>
            </div>
            <div class="vx-col w-full">
              <a class="add-plus-segment" @click="addCriteria()">
                <feather-icon icon="PlusIcon"></feather-icon> Add
              </a>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8 sm:px-8 px-4 w-full sm:w-auto">
        <vs-button
          v-if="!segmentEditMode"
          type="filled"
          class="mr-4 w-full sm:w-auto mb-2 sm:mb-0"
          color="primary"
          @click="addSegment()"
          >{{ LabelConstant.buttonLabelAddSegment }}</vs-button
        >
        <vs-button
          v-if="segmentEditMode"
          type="filled"
          class="mr-4 w-full sm:w-auto mb-2 sm:mb-0"
          color="primary"
          @click="updateSegment()"
          >{{ LabelConstant.buttonLabelEditSegment }}</vs-button
        >
        <vs-button
          color="grey"
          class="grey-btn w-full sm:w-auto"
          type="border"
          @click="cancelAddSegment()"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Delete Segment Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteSegmentActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure to delete this Segment?</h4>
            <span>{{ deletableSegmentObj.Name }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled" @click="deleteSegment()"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          color="grey"
          @click="cancelDeleteSegment()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Delete Contact Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteContactPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure to delete this Contact?</h4>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled" @click="deleteContact()">
          {{ LabelConstant.buttonLabelDelete }}
        </vs-button>
        <vs-button
          color="grey"
          @click="deleteContactPopupActive = false"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Delete Tag Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteTagActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure to delete this Tag?</h4>
            <p>{{ addTagName }}</p>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled" @click="deleteTag()"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          color="grey"
          @click="deleteTagActive = false"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Selected Contact Unsubscribe -->
    <!-- <vs-popup class="tag-info" title="Unsubscribe" :active.sync="unsubscribeSelectedContact">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="popup-icon">
              <img src="../../../src/assets/images/silence.png" />
              <p>
                Are you sure You would like to unsubscribe all selected
                contacts?
              </p>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" button="submit" color="primary"
          >Unsubscribe</vs-button
        >
        <vs-button
          @click="unsubscribeSelectedContact = false"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup> -->

    <!-- Selected Contact Resubscribe -->
    <!-- <vs-popup class="tag-info" title="Resubscribe" :active.sync="resubscribeSelectedContact">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="popup-icon">
              <img src="../../../src/assets/images/bell.png" />
              <p>
                Are you sure You would like to resubscribe all selected
                contacts?
              </p>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" button="submit" color="primary"
          >resubscribe</vs-button
        >
        <vs-button
          @click="resubscribeSelectedContact = false"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup> -->

    <!-- Selected Contact Delete -->
    <!-- <vs-popup class="delete-popup" :active.sync="deleteSelectedContact">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>
              Are you sure You would like to Delete all selected contacts?
            </h4>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button
          color="grey"
          @click="deleteSelectedContact = false"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup> -->

    <!-- Add,Edit Tag -->
    <vs-popup
      class="tag-info"
      :title="tagId != 0 ? 'Update Tag' : 'Add New Tag'"
      :active.sync="addTagActive"
    >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="popup-icon">
              <img src="../../assets/images/tag.svg" />
            </div>
            <form data-vv-scope="tagForm">
              <div class="vx-row">
                <div class="vx-col w-full mt-2">
                  <vs-input
                    type="text"
                    class="w-full"
                    name="addTagName"
                    label-placeholder="Tag"
                    v-validate="'required'"
                    v-model="addTagName"
                  />
                  <span class="text-danger text-sm mb-3 float-left w-full">{{
                    errors.first("tagForm.addTagName")
                  }}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="primary" type="filled" @click="addTag()">{{
          tagId != 0 ? LabelConstant.buttonLabelUpdate : LabelConstant.buttonLabelAdd
        }}</vs-button>
        <vs-button
          color="grey"
          @click="cancelAddTag()"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<!-- Script -->

<script>
import VxCard from "@/components/vx-card/VxCard";
import SingleCard from "../../components/SingleCard";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueApexCharts from "vue-apexcharts";
import { GChart } from "vue-google-charts";
import { Validator } from "vee-validate";

import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
// import demoBase64 from "demoBase64.js"
import OverviewTab from '../../components/email-marketing/contacts/OverviewTab.vue';
import ContactsTab from '../../components/email-marketing/contacts/ContactsTab.vue';
import SegmentsTab from '../../components/email-marketing/contacts/SegmentsTab.vue';
import TagsTab from '../../components/email-marketing/contacts/TagsTab.vue';
import emailMarketingContactsMixin from "../../mixins/emailMarketingContactsMixin";

const dict = {
  custom: {
    addTagName: {
      required: "Please enter tag name",
    },
  },
};
Validator.localize("en", dict);
export default {
  mixins: [emailMarketingContactsMixin],
  components: {
    VxCard,
    SingleCard,
    vSelect,
    VuePerfectScrollbar,
    VueApexCharts,
    GChart,
    AgGridVue,
    OverviewTab,
    ContactsTab,
    SegmentsTab,
    TagsTab
  }
};
</script>
