<template>
    <div class="contact-overview">
        <!-- Total Contacts, Subscribed contacts, Unsubscribed contacts, Segment Created -->
        <div class="vx-row">
            <!-- Total Contacts -->
            <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
              <single-card
                icon="PhoneIcon"
                smallTitle="Total Contacts"
                :totalValue="totalContacts.StatEndValue"
                :bottomText="''"
                :upDownValue="totalContacts.StatChangePercent + '%'"
                Upicon="ArrowDownIcon"
                textcolor="text-danger"
                bgcolor="bg-first-gradient"
                maintextcolor="text-first"
                bgimg="telephone.png"
                emitTagChange="Total Contacts"
                @changeTab="$emit('changeTab')"
              ></single-card>
            </div>
            <!-- Subscribed contacts -->
            <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
              <single-card
                icon="BellIcon"
                smallTitle="Subscribed Contacts"
                :totalValue="SubscribedContacts.StatEndValue"
                :bottomText="''"
                :upDownValue="SubscribedContacts.StatChangePercent + '%'"
                Upicon="ArrowDownIcon"
                textcolor="text-danger"
                bgcolor="bg-second-gradient"
                maintextcolor="text-second"
                bgimg="bell.png"
                viewAllLink="Subscribed Contacts"
              ></single-card>
            </div>
            <!-- Unsubscribed contacts -->
            <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
              <single-card
                icon="BellOffIcon"
                smallTitle="Unsubscribed Contacts"
                :positiveNegative="true"
                :totalValue="unSubscribedContacts.StatEndValue"
                :bottomText="''"
                :upDownValue="unSubscribedContacts.StatChangePercent + '%'"
                Upicon="ArrowDownIcon"
                textcolor="text-danger"
                bgcolor="bg-third-gradient"
                maintextcolor="text-third"
                bgimg="silence.png"
                viewAllLink="Unsubscribed Contacts"
              ></single-card>
            </div>
            <!-- Segment Created -->
            <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
              <single-card
                icon="EditIcon"
                smallTitle="Segments Created"
                :totalValue="segmentCreated.StatEndValue"
                :bottomText="''"
                :upDownValue="segmentCreated.StatChangePercent + '%'"
                Upicon="ArrowDownIcon"
                textcolor="text-danger"
                bgcolor="bg-fourth-gradient"
                maintextcolor="text-fourth"
                bgimg="silence.png"
                emitTagChange="Segments Created"
                @changeTab="$emit('changeTab')"
              ></single-card>
            </div>
        </div>
        <!-- Latest Campaign Performance -->
        <div class="vx-row mb-8">
            <div class="vx-col w-full">
              <vx-card>
                <div class="card-title">
                  <h2>Latest Campaign Performance</h2>
                </div>
                <vue-apex-charts
                  type="line"
                  height="350"
                  :options="campaignPerformance.chartOptions"
                  :series="campaignPerformance.series"
                ></vue-apex-charts>
              </vx-card>
            </div>
        </div>
        <!-- Total Email Clients -->
        <div class="vx-row mb-8">
            <div class="vx-col w-full">
              <vx-card>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="card-title">
                      <h2>Total Email Clients</h2>
                    </div>
                  </div>
                  <div class="vx-col w-full xl:w-1/2">
                    <div class="vx-row">
                      <div class="vx-col w-full sm:w-1/2">
                        <vue-apex-charts
                          type="radialBar"
                          height="280"
                          :options="radialDasktopChart.chartOptions"
                          :series="radialDasktopChart.series"
                        ></vue-apex-charts>
                      </div>
                      <div class="vx-col w-full sm:w-1/2">
                        <vue-apex-charts
                          type="radialBar"
                          height="280"
                          :options="radialMobileChart.chartOptions"
                          :series="radialMobileChart.series"
                        ></vue-apex-charts>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col w-full xl:w-1/2">
                    <div class="clients-table">
                      <ul>
                        <li>
                          <h4>
                            <span> {{ totalClientDesktopPercentage }}%</span>
                            Desktop Client
                          </h4>
                        </li>
                        <li
                          v-for="(topclient, index) in topClientDesktop"
                          :key="index"
                        >
                          <span>{{ topclient.x }}</span>
                          <span>{{ topclient.FirstGroup }}%</span>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <h4>
                            <span> {{ totalClientMobilePercentage }}%</span>
                            Mobile Client
                          </h4>
                        </li>
                        <li
                          v-for="(topclient, index) in topClientMobile"
                          :key="index"
                        >
                          <span>{{ topclient.x }}</span>
                          <span>{{ topclient.FirstGroup }}%</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </vx-card>
            </div>
        </div>
        <!-- Top Locations -->
        <div class="vx-row">
            <div class="vx-col w-full">
              <vx-card>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="card-title">
                      <h2>Top Locations</h2>
                    </div>
                  </div>
                  <div class="vx-col w-full md:w-2/3">
                    <GChart
                      :settings="{
                        packages: ['corechart', 'table', 'geochart'],
                        mapsApiKey: googleMapApiKey,
                      }"
                      type="GeoChart"
                      :data="chartData"
                      :options="chartOptions"
                    />
                  </div>
                  <div class="vx-col w-full md:w-1/3">
                    <vs-table
                      ref=""
                      :data="countryLocation"
                      :max-items="maxRecordPerPage"
                      pagination
                    >
                      <template slot="thead">
                        <vs-th sort-key="Country">Country</vs-th>
                        <vs-th sort-key="Percent">Percent</vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                        >
                          <vs-td :data="tr.x">
                            {{ tr.x }}
                          </vs-td>
                          <vs-td :data="tr.FirstGroup">
                            {{ tr.FirstGroup + "%" }}
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
              </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import SingleCard from "../../../components/SingleCard";
import VueApexCharts from "vue-apexcharts";
import { GChart } from "vue-google-charts";

export default ({
    components: {
        VxCard,
        SingleCard,
        VueApexCharts,
        GChart
    },
    data(){
      return{
        googleMapApiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      }
    },
    props:{
        totalContacts:{
            type: Object,
            required: true
        },
        SubscribedContacts:{
            type: Object,
            required: true
        },
        unSubscribedContacts:{
            type: Object,
            required: true
        },
        segmentCreated:{
            type: Object,
            required: true
        },
        campaignPerformance:{
            type: Object,
            required: true
        },
        radialDasktopChart:{
            type: Object,
            required: true
        },
        radialMobileChart:{
            type: Object,
            required: true
        },
        totalClientDesktopPercentage:{
            required: true
        },
        totalClientMobilePercentage:{
            required: true
        },
        topClientDesktop:{
            type: Array,
            required: true
        },
        topClientMobile:{
            type: Array,
            required: true
        },
        chartData:{
            type: Array,
            required: true
        },
        chartOptions:{
            type: Object,
            required: true
        },
        countryLocation:{
            type: Array,
            required: true
        },
        maxRecordPerPage:{
            type: Number,
            required: true
        }
    }
})
</script>
