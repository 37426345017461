<template>
    <div class="segments-tab">
        <vx-card>
            <div class="card-title justify-end">
              <vs-button
                color="primary"
                @click="$emit('clickToAddTag')"
                icon-pack="feather"
                icon="icon-plus"
                type="filled"
                >{{ LabelConstant.buttonLabelAddTag }}</vs-button
              >
            </div>
            <vs-table class="table-list" ref="table" :data="tags">
              <template slot="thead">
                <vs-th></vs-th>
                <vs-th sort-key="tag">{{ LabelConstant.tableHeaderLabelTagName }}</vs-th>
                <vs-th sort-key="datecreated">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td width="180">
                    <vs-button
                      title="Edit"
                      @click="$emit('clickToEditTag', tr.Id)"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-edit"
                      class="mr-2 float-left"
                      color="primary"
                    ></vs-button>
                    <vs-button
                      title="View"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-eye"
                      class="mr-2 float-left"
                      color="success"
                      @click="$emit('clickToViewFilter', tr, 'tag')"
                    ></vs-button>
                    <vs-button
                      title="Delete"
                      @click="$emit('clickToDeleteTag', tr)"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-trash-2"
                      class="mr-0"
                    ></vs-button>
                  </vs-td>
                  <vs-td>
                    <p>{{ tr.Tag_Name }}</p>
                  </vs-td>
                  <vs-td>
                    <p>{{ formattedDate(tr.Date_Created) }}</p>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
        </vx-card>
    </div>
</template>

<script>
import moment from "moment";

export default ({
    props:{
        tags:{
            type: Array,
            required: true
        }
    },
    methods:{
        formattedDate(date) {
          return moment(date).format("MMMM DD,YYYY");
        },
    }
})
</script>
